import { SearchFields } from "./list";

export type CommunicationAutocomplete = {
  id: number;
  name: string;
}[];

export const communicationSearchFields: SearchFields = [
  { label: "Name", field: "name", placeholder: "Communication name" },
  {
    label: "ID",
    field: "id",
    placeholder: "Communication ID",
    isNumber: true,
  },
];

export enum CommunicationFields {
  include_inactive = "include_inactive",
  category_id = "category_id",
  type = "type",
}

export const communicationSortFields = [
  { field: "id", label: "ID" },
  { field: "category_name", label: "Category" },
  { field: "name", label: "Name" },
  { field: "communication_type", label: "Type" },
  { field: "offset", label: "Offset" },
  { field: "active", label: "Active" },
];

export const communicationBundleSearchFields: SearchFields = [
  { label: "Name", field: "name", placeholder: "Bundle name" },
  {
    label: "ID",
    field: "id",
    placeholder: "Bundle ID",
    isNumber: true,
  },
];

export enum CommunicationBundleFields {
  include_inactive = "include_inactive",
}

export const communicationBundleSortFields = [
  { field: "id", label: "ID" },
  { field: "bundle_name", label: "Bundle name" },
  { field: "active", label: "Active" },
  { field: "is_rep_restricted", label: "Rep restricted" },
];

export const communicationCategorySearchFields: SearchFields = [
  { label: "Name", field: "name", placeholder: "Category name" },
  {
    label: "ID",
    field: "id",
    placeholder: "Category ID",
    isNumber: true,
  },
];

export const communicationCategorySortFields = [
  { field: "id", label: "ID" },
  { field: "category_name", label: "Category name" },
];

export type CommunicationOffsetOptions = { name: string; value: number }[];

const allOffsetOptions: CommunicationOffsetOptions = [];
const notNegativeOffsetOptions: CommunicationOffsetOptions = [];
const positiveOffsetOptions: CommunicationOffsetOptions = [];

for (let i = -14; i < 15; i++) {
  let name = "";
  switch (true) {
    case i < 0:
      name = `${Math.abs(i)} Day${i !== -1 ? "s" : ""} before`;
      break;
    case i === 0:
      name = "The Day of";
      break;
    case i === 1:
      name = `${i} Day after`;
      break;
    default:
      name = `${i} Days after`;
  }
  allOffsetOptions.push({ name, value: i });
  if (i >= 0) notNegativeOffsetOptions.push({ name, value: i });
  if (i > 0) positiveOffsetOptions.push({ name, value: i });
}

export const maxContactOptions = Array.from({ length: 30 }, (_, i) => i + 1);

export enum CommunicationTypes {
  contactsFirstEmail = "Student contacts - first email",
  contactsSecondEmail = "Student contacts - second email",
  contactsThirdEmail = "Student contacts - third email",
  contactsFourthEmail = "Student contacts - fourth email",
  contactsFifthEmail = "Student contacts - fifth email",
  contactsFirstText = "Student contacts - first text",
  contactsSecondText = "Student contacts - second text",
  contactsThirdText = "Student contacts - third text",
  allStudentsKickoff = "All students - kickoff",
  allStudentsCampaignStart = "All students - campaign start",
  activeStudentsKickoff = "Active students - kickoff",
  activeStudentsCampaignStart = "Active students - campaign start",
  activeStudentsCampaignEnd = "Active students - campaign end",
  activeStudentsPercentOfCampaignGoal = "Active students - percent of campaign goal",
  activeStudentsPercentOfStudentGoal = "Active students - percent of student goal",
  activeStudentsFirstText = "Active students - first text",
  activeStudentsSecondText = "Active students - second text",
  activeStudentsThirdText = "Active students - third text",
  activeStudentsFourthText = "Active students - fourth text",
  inactiveStudentsKickoff = "Inactive students - kickoff",
  inactiveStudentsCampaignStart = "Inactive students - campaign start",
  STHCustomersCampaignEnd = "STH customers - campaign end",
  STSCustomersCampaignEnd = "STS customers - campaign end",
  orgKickoff = "Org - kickoff",
  orgCampaignStart = "Org - campaign start",
  orgCampaignEnd = "Org - campaign end",
  orgOrderFormsDue = "Org - Order forms due to company",
  orgReadyForFulfillment = "Org - Ready for fulfillment",
  orgPercentOfCampaignGoal = "Org - percent of campaign goal",
  repKickoff = "Rep - kickoff",
  repCampaignStart = "Rep - campaign start",
  repCampaignEnd = "Rep - campaign end",
  repReadyForFulfillment = "Rep - Ready for fulfillment",
  repPercentOfCampaignGoal = "Rep - percent of campaign goal",
}

export type CommunicationMergeFields = { label: string; value: string }[];

const merges = {
  adminPortalContactPageLink: {
    value: "adminPortalContactPageLink",
    label: "Admin portal contacts page link",
  },
  adminPortalEZSharePageLink: {
    value: "adminPortalEZSharePageLink",
    label: "Admin portal EZ share page link",
  },
  adminPortalHomePageLink: {
    value: "adminPortalHomePageLink",
    label: "Admin portal home page link",
  },
  campaignGoal: { value: "campaignGoal", label: "Campaign goal" },
  campaignId: { value: "campaignId", label: "Campaign ID" },
  campaignName: { value: "campaignName", label: "Campaign name" },
  campaignRaised: { value: "campaignRaised", label: "Campaign raised" },
  companyName: { value: "companyName", label: "Company name" },
  companySupportEmail: {
    value: "companySupportEmail",
    label: "Company support email",
  },
  customerFirstName: {
    value: "customerFirstName",
    label: "Customer first name",
  },
  customerFullName: { value: "customerFullName", label: "Customer full name" },
  orderFormsDueToCompanyDate: {
    value: "orderFormsDueToCompanyDate",
    label: "Order forms due to company date",
  },
  orgContactName: { value: "orgContactName", label: "Org contact name" },
  orgName: { value: "orgName", label: "Org name" },
  percentOfCampaignGoalRaised: {
    value: "percentOfCampaignGoalRaised",
    label: "Percent of campaign goal raised",
  },
  percentOfStudentGoalRaised: {
    value: "percentOfStudentGoalRaised",
    label: "Percent of student goal raised",
  },
  repFirstName: { value: "repFirstName", label: "Rep first name" },
  repFullName: { value: "repFullName", label: "Rep full name" },
  studentContactName: {
    value: "studentContactName",
    label: "Contact name",
  },
  studentContactsCount: {
    value: "studentContactsCount",
    label: "Student contacts count",
  },
  studentEmail: { value: "studentEmail", label: "Student email" },
  studentEmailCount: {
    value: "studentEmailCount",
    label: "Student email count",
  },
  studentFirstName: { value: "studentFirstName", label: "Student first name" },
  studentFullName: { value: "studentFullName", label: "Student full name" },
  studentGoal: { value: "studentGoal", label: "Student goal" },
  studentId: { value: "studentId", label: "Student ID" },
  studentPhoneCount: {
    value: "studentPhoneCount",
    label: "Student phone count",
  },
  studentRaised: { value: "studentRaised", label: "Student raised" },
  studentWebpageShortLink: {
    value: "studentWebpageShortLink",
    label: "Student webpage short link",
  },
  webpageLink: { value: "webpageLink", label: "Webpage link" },
  webpageRegistrationLink: {
    value: "webpageRegistrationLink",
    label: "Webpage registration link",
  },
};

// for copying
const _allMergeFields: CommunicationMergeFields = [
  merges.adminPortalContactPageLink,
  merges.adminPortalEZSharePageLink,
  merges.adminPortalHomePageLink,
  merges.campaignGoal,
  merges.campaignId,
  merges.campaignName,
  merges.campaignRaised,
  merges.companyName,
  merges.companySupportEmail,
  merges.customerFirstName,
  merges.customerFullName,
  merges.orderFormsDueToCompanyDate,
  merges.orgContactName,
  merges.orgName,
  merges.percentOfCampaignGoalRaised,
  merges.percentOfStudentGoalRaised,
  merges.repFirstName,
  merges.repFullName,
  merges.studentContactName,
  merges.studentContactsCount,
  merges.studentEmail,
  merges.studentEmailCount,
  merges.studentFirstName,
  merges.studentFullName,
  merges.studentGoal,
  merges.studentId,
  merges.studentPhoneCount,
  merges.studentRaised,
  merges.webpageLink,
  merges.webpageRegistrationLink,
];

const contactMergeFields: CommunicationMergeFields = [
  merges.studentContactName,
  merges.campaignGoal,
  merges.campaignId,
  merges.campaignName,
  merges.companyName,
  merges.companySupportEmail,
  merges.orgName,
  merges.percentOfStudentGoalRaised,
  merges.studentEmail,
  merges.studentFirstName,
  merges.studentFullName,
  merges.studentGoal,
  merges.studentRaised,
  merges.webpageLink,
];

const contactTextMergeFields: CommunicationMergeFields = [
  merges.companyName,
  merges.orgName,
  merges.studentContactName,
  merges.studentFirstName,
  merges.studentFullName,
  merges.studentWebpageShortLink,
];

const studentMergeFields: CommunicationMergeFields = [
  merges.adminPortalContactPageLink,
  merges.adminPortalEZSharePageLink,
  merges.adminPortalHomePageLink,
  merges.campaignGoal,
  merges.campaignId,
  merges.campaignName,
  merges.companyName,
  merges.companySupportEmail,
  merges.orgName,
  merges.studentContactsCount,
  merges.studentEmail,
  merges.studentEmailCount,
  merges.studentFirstName,
  merges.studentFullName,
  merges.studentGoal,
  merges.studentId,
  merges.studentPhoneCount,
  merges.webpageLink,
];

const studentPercentOfCampaignGoalMergeFields: CommunicationMergeFields = [
  merges.adminPortalContactPageLink,
  merges.adminPortalEZSharePageLink,
  merges.adminPortalHomePageLink,
  merges.campaignGoal,
  merges.campaignId,
  merges.campaignName,
  merges.campaignRaised,
  merges.companyName,
  merges.companySupportEmail,
  merges.orgName,
  merges.percentOfCampaignGoalRaised,
  merges.studentContactsCount,
  merges.studentEmail,
  merges.studentEmailCount,
  merges.studentFirstName,
  merges.studentFullName,
  merges.studentGoal,
  merges.studentId,
  merges.studentPhoneCount,
  merges.webpageLink,
];

const studentPercentOfStudentGoalMergeFields: CommunicationMergeFields = [
  merges.adminPortalContactPageLink,
  merges.adminPortalEZSharePageLink,
  merges.adminPortalHomePageLink,
  merges.campaignGoal,
  merges.campaignId,
  merges.campaignName,
  merges.companyName,
  merges.companySupportEmail,
  merges.orgName,
  merges.percentOfStudentGoalRaised,
  merges.studentContactsCount,
  merges.studentEmail,
  merges.studentEmailCount,
  merges.studentFirstName,
  merges.studentFullName,
  merges.studentGoal,
  merges.studentId,
  merges.studentPhoneCount,
  merges.studentRaised,
  merges.webpageLink,
];

const studentTextMergeFields: CommunicationMergeFields = [
  merges.adminPortalContactPageLink,
  merges.adminPortalEZSharePageLink,
  merges.adminPortalHomePageLink,
  merges.campaignId,
  merges.companyName,
  merges.orgName,
  merges.studentFirstName,
  merges.studentFullName,
  merges.studentId,
  merges.studentWebpageShortLink,
];

const customerMergeFields: CommunicationMergeFields = [
  merges.campaignId,
  merges.campaignName,
  merges.companyName,
  merges.companySupportEmail,
  merges.customerFirstName,
  merges.customerFullName,
  merges.orgName,
];

const orgRepMergeFields: CommunicationMergeFields = [
  merges.adminPortalHomePageLink,
  merges.campaignGoal,
  merges.campaignId,
  merges.campaignName,
  merges.companyName,
  merges.companySupportEmail,
  merges.orderFormsDueToCompanyDate,
  merges.orgContactName,
  merges.orgName,
  merges.repFirstName,
  merges.repFullName,
  merges.webpageLink,
  merges.webpageRegistrationLink,
];

const orgRepPercentOfGoalMergeFields: CommunicationMergeFields = [
  merges.adminPortalHomePageLink,
  merges.campaignGoal,
  merges.campaignId,
  merges.campaignName,
  merges.campaignRaised,
  merges.companyName,
  merges.companySupportEmail,
  merges.orgContactName,
  merges.orgName,
  merges.percentOfCampaignGoalRaised,
  merges.repFirstName,
  merges.repFullName,
  merges.webpageLink,
  merges.webpageRegistrationLink,
];

export type CommunicationTypeConfig = {
  offsetLabel?: string;
  allowOrgLogoInsert?: boolean;
  allowVolunteerPictureInsert?: boolean;
  allowSocialMediaInsert?: boolean;
  allowWebpageButton?: boolean;
  noOffset?: boolean;
  showPercents?: boolean;
  allowCcRep?: boolean;
  mergeFields: CommunicationMergeFields;
  offsetOptions: CommunicationOffsetOptions;
  showMaxContactsPerVolunteer?: boolean;
  isSms?: boolean;
};

export const communicationTypeConfigs: Record<
  CommunicationTypes,
  CommunicationTypeConfig
> = {
  [CommunicationTypes.contactsFirstEmail]: {
    offsetLabel: "Days from campaign start",
    allowOrgLogoInsert: true,
    allowVolunteerPictureInsert: true,
    allowSocialMediaInsert: true,
    allowWebpageButton: true,
    offsetOptions: notNegativeOffsetOptions,
    mergeFields: contactMergeFields,
  },
  [CommunicationTypes.contactsSecondEmail]: {
    offsetLabel: "Days from first email",
    allowOrgLogoInsert: true,
    allowVolunteerPictureInsert: true,
    allowSocialMediaInsert: true,
    allowWebpageButton: true,
    offsetOptions: positiveOffsetOptions,
    mergeFields: contactMergeFields,
  },
  [CommunicationTypes.contactsThirdEmail]: {
    offsetLabel: "Days from second email",
    allowOrgLogoInsert: true,
    allowVolunteerPictureInsert: true,
    allowSocialMediaInsert: true,
    allowWebpageButton: true,
    offsetOptions: positiveOffsetOptions,
    mergeFields: contactMergeFields,
  },
  [CommunicationTypes.contactsFourthEmail]: {
    offsetLabel: "Days from third email",
    allowOrgLogoInsert: true,
    allowVolunteerPictureInsert: true,
    allowSocialMediaInsert: true,
    allowWebpageButton: true,
    offsetOptions: positiveOffsetOptions,
    mergeFields: contactMergeFields,
  },
  [CommunicationTypes.contactsFifthEmail]: {
    offsetLabel: "Days from fourth email",
    allowOrgLogoInsert: true,
    allowVolunteerPictureInsert: true,
    allowSocialMediaInsert: true,
    allowWebpageButton: true,
    offsetOptions: positiveOffsetOptions,
    mergeFields: contactMergeFields,
  },
  [CommunicationTypes.contactsFirstText]: {
    offsetLabel: "Days from campaign start",
    offsetOptions: notNegativeOffsetOptions,
    showMaxContactsPerVolunteer: true,
    isSms: true,
    mergeFields: contactTextMergeFields,
  },
  [CommunicationTypes.contactsSecondText]: {
    offsetLabel: "Days from first text",
    offsetOptions: positiveOffsetOptions,
    isSms: true,
    mergeFields: contactTextMergeFields,
  },
  [CommunicationTypes.contactsThirdText]: {
    offsetLabel: "Days from second text",
    offsetOptions: positiveOffsetOptions,
    isSms: true,
    mergeFields: contactTextMergeFields,
  },
  [CommunicationTypes.allStudentsKickoff]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: studentMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.allStudentsCampaignStart]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: studentMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.activeStudentsKickoff]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: studentMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.activeStudentsCampaignStart]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: studentMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.activeStudentsCampaignEnd]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: studentMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.activeStudentsPercentOfCampaignGoal]: {
    noOffset: true,
    showPercents: true,
    offsetOptions: [],
    mergeFields: studentPercentOfCampaignGoalMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.activeStudentsPercentOfStudentGoal]: {
    noOffset: true,
    showPercents: true,
    offsetOptions: [],
    mergeFields: studentPercentOfStudentGoalMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.activeStudentsFirstText]: {
    offsetLabel: "Days from campaign start",
    isSms: true,
    offsetOptions: notNegativeOffsetOptions,
    mergeFields: studentTextMergeFields,
  },
  [CommunicationTypes.activeStudentsSecondText]: {
    offsetLabel: "Days from first text",
    isSms: true,
    offsetOptions: positiveOffsetOptions,

    mergeFields: studentTextMergeFields,
  },
  [CommunicationTypes.activeStudentsThirdText]: {
    offsetLabel: "Days from second text",
    isSms: true,
    offsetOptions: positiveOffsetOptions,
    mergeFields: studentTextMergeFields,
  },
  [CommunicationTypes.activeStudentsFourthText]: {
    offsetLabel: "Days from third text",
    isSms: true,
    offsetOptions: positiveOffsetOptions,
    mergeFields: studentTextMergeFields,
  },
  [CommunicationTypes.inactiveStudentsKickoff]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: studentMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.inactiveStudentsCampaignStart]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: studentMergeFields,
    allowOrgLogoInsert: true,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.STHCustomersCampaignEnd]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: customerMergeFields,
    allowOrgLogoInsert: true,
  },
  [CommunicationTypes.STSCustomersCampaignEnd]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: customerMergeFields,
    allowOrgLogoInsert: true,
  },
  [CommunicationTypes.orgKickoff]: {
    offsetLabel: "Days offset",
    allowCcRep: true,
    offsetOptions: allOffsetOptions,
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.orgCampaignStart]: {
    offsetLabel: "Days offset",
    allowCcRep: true,
    offsetOptions: allOffsetOptions,
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.orgCampaignEnd]: {
    offsetLabel: "Days offset",
    allowCcRep: true,
    offsetOptions: allOffsetOptions,
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.orgOrderFormsDue]: {
    offsetLabel: "Days offset",
    allowCcRep: true,
    offsetOptions: allOffsetOptions,
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.orgReadyForFulfillment]: {
    noOffset: true,
    offsetOptions: [],
    allowCcRep: true,
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.orgPercentOfCampaignGoal]: {
    noOffset: true,
    showPercents: true,
    allowCcRep: true,
    offsetOptions: [],
    mergeFields: orgRepPercentOfGoalMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.repKickoff]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.repCampaignStart]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.repCampaignEnd]: {
    offsetLabel: "Days offset",
    offsetOptions: allOffsetOptions,
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.repReadyForFulfillment]: {
    noOffset: true,
    offsetOptions: [],
    mergeFields: orgRepMergeFields,
    allowSocialMediaInsert: true,
  },
  [CommunicationTypes.repPercentOfCampaignGoal]: {
    noOffset: true,
    showPercents: true,
    offsetOptions: [],
    mergeFields: orgRepPercentOfGoalMergeFields,
    allowSocialMediaInsert: true,
  },
};

export const campaignCommunicationSortFields = [
  { field: "name", label: "Name" },
  { field: "communication_type", label: "Type" },
  { field: "offset", label: "Offset" },
];
