import { APIAuthClient } from "../../lib";
import { APIRes, ToastTypes } from "../../types";
import { setToast, toggleLoadingDownload } from "../ui/actions";
import { AppThunkAction } from "../../types/state";
import { campaignTypes } from "../campaign/actions";

const contactTypes = {
  CONTACTS_SET: "CONTACTS_SET",
  CONVERTIBLE_DONORS_SET: "CONVERTIBLE_DONORS_SET",
  CONVERTIBLE_DONORS_CLEAR: "CONVERTIBLE_DONORS_CLEAR",
};

const contactActions = {
  fetchContacts(): AppThunkAction<Promise<boolean>> {
    return async (dispatch, getState) => {
      const {
        campaign: { campaignId },
      } = getState();
      const url = `/contacts?campaign_id=${campaignId}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return false;
      }
      dispatch({ type: contactTypes.CONTACTS_SET, data });
      return true;
    };
  },
  addContacts(
    post: any,
    count: number,
    noSuccessMsg = false,
  ): AppThunkAction<Promise<boolean>> {
    return async dispatch => {
      const url = "/contacts/bulk-create";
      const response = await APIAuthClient.post<any, APIRes>(url, post);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return false;
      }
      if (!noSuccessMsg) {
        dispatch(setToast(`${count} contacts added`, ToastTypes.success));
      }
      dispatch({ type: contactTypes.CONTACTS_SET, data });
      return true;
    };
  },
  editContact(update: any, id: number): AppThunkAction<Promise<boolean>> {
    return async dispatch => {
      const url = `/contacts/${id}`;
      const response = await APIAuthClient.put<any, APIRes>(url, update);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return false;
      }
      dispatch(setToast("Contact updated", ToastTypes.success));
      dispatch({ type: contactTypes.CONTACTS_SET, data });
      return true;
    };
  },
  deleteContact(
    id: number,
    campaign_id: number,
  ): AppThunkAction<Promise<boolean>> {
    return async dispatch => {
      const url = `/contacts/${id}?campaign_id=${campaign_id}`;
      const response = await APIAuthClient.delete<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return false;
      }
      dispatch(setToast("Contact deleted", ToastTypes.success));
      dispatch({ type: contactTypes.CONTACTS_SET, data });
      return true;
    };
  },
  confirmContacts(
    campaign_id: number,
    showToast?: boolean,
  ): AppThunkAction<Promise<boolean>> {
    return async dispatch => {
      const url = `/contacts/confirm_contacts?campaign_id=${campaign_id}`;
      const response = await APIAuthClient.post<any, APIRes>(url);
      const { error, errorMessage } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return false;
      }
      if (showToast) {
        dispatch(setToast("Contacts confirmed", ToastTypes.success));
      }
      dispatch({ type: campaignTypes.CONTACTS_MARK_CONFIRMED });
      return true;
    };
  },
  emailContacts(post: any): AppThunkAction<Promise<boolean>> {
    return async dispatch => {
      const url = "/contacts/send-emails";
      const response = await APIAuthClient.post<any, APIRes>(url, post);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return false;
      }
      dispatch(setToast("We're sending your emails", ToastTypes.success));
      dispatch({ type: contactTypes.CONTACTS_SET, data });
      return true;
    };
  },
  fetchConvertibleDonors(): AppThunkAction {
    return async dispatch => {
      const url = "/contacts/convertible_donors";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      dispatch({ type: contactTypes.CONVERTIBLE_DONORS_SET, data });
    };
  },
  clearConvertibleDonors() {
    return { type: contactTypes.CONVERTIBLE_DONORS_CLEAR };
  },
  sendCampaignDripEmails(onClose?: () => void): AppThunkAction {
    return async (dispatch, getState) => {
      dispatch(toggleLoadingDownload(true));
      const {
        campaign: { campaignId },
      } = getState();
      const url = `/contacts/send-drips?campaign_id=${campaignId}`;
      const response = await APIAuthClient.post<any, APIRes>(url, {});
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
      } else {
        if (data.hasSendableContacts) {
          dispatch(setToast("Drip Emails have been sent.", ToastTypes.success));
        } else {
          dispatch(setToast("There are no Emails to send at this time."));
        }
        if (onClose) onClose();
      }
      dispatch(toggleLoadingDownload(false));
    };
  },
  confirmCampaignContacts(
    refreshList: () => void,
    onClose?: () => void,
  ): AppThunkAction {
    return async (dispatch, getState) => {
      dispatch(toggleLoadingDownload(true));
      const {
        campaign: { campaignId },
      } = getState();
      const url = `/contacts/confirm-campaign-contacts?campaign_id=${campaignId}`;
      const response = await APIAuthClient.post<any, APIRes>(url, {});
      const { error, errorMessage } = response;
      if (error) {
        dispatch(setToast(errorMessage));
      } else {
        refreshList();
        dispatch(
          setToast("Contacts successfully confirmed", ToastTypes.success),
        );
        if (onClose) onClose();
      }
      dispatch(toggleLoadingDownload(false));
    };
  },
  createManualTextSent(post: any): AppThunkAction<Promise<boolean>> {
    return async dispatch => {
      const url = "/manual_sent_texts";
      const response = await APIAuthClient.post<any, APIRes>(url, post);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return false;
      }
      dispatch({ type: contactTypes.CONTACTS_SET, data });
      return true;
    };
  },
};

export { contactTypes, contactActions };
