import { IconButton, makeStyles, Button, Checkbox } from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Contact, GenericObject, TOP_BAR_HEIGHT_MOBILE } from "../../../types";
import { RootState } from "../../../types/state";
import classNames from "classnames";

type Props = {
  setShowSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEmailSelector: React.Dispatch<React.SetStateAction<boolean>>;
  updateEmailRecipients: (
    id: number,
    email: string | null,
    add: boolean,
    clearOthers?: boolean,
  ) => void;
  emailRecipients: GenericObject;
  clearEmailRecipients: () => void;
};

export function MobileEmailRecipientSelector({
  setShowSendEmail,
  setShowEmailSelector,
  updateEmailRecipients,
  emailRecipients,
  clearEmailRecipients,
}: Props) {
  const classes = styles();
  const contacts = useSelector((state: RootState) => state.contacts.contacts);

  const onCancel = () => {
    setShowEmailSelector(false);
    clearEmailRecipients();
  };

  const onSend = () => {
    setShowSendEmail(true);
    setShowEmailSelector(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.closeAndTitle}>
          <IconButton size="small" onClick={onCancel}>
            <CloseIcon />
          </IconButton>
          <div className={classes.title}>Select recipients</div>
        </div>
        <Button
          color="secondary"
          startIcon={<MailOutlineIcon />}
          disabled={!Object.keys(emailRecipients).length}
          onClick={onSend}
          className={classes.button}
        >
          Compose email
        </Button>
      </div>
      <div className={classes.content}>
        {contacts.map((c, index) => {
          const { id, email, name, invalid_email } = c as Contact;
          const disable = Boolean(!email || invalid_email);
          const lastRow = index === contacts.length - 1;
          return (
            <div
              key={index}
              className={classNames(classes.row, {
                [classes.lastRow]: lastRow,
              })}
            >
              <div>
                <Checkbox
                  classes={{ checked: classes.checked }}
                  onChange={({ target }) =>
                    updateEmailRecipients(id, email, target.checked)
                  }
                  disabled={disable}
                />
              </div>
              <div className={classes.nameAndEmail}>
                <div
                  className={classNames(classes.name, {
                    [classes.disable]: disable,
                  })}
                >
                  {name}
                </div>
                <div className={classes.email}>{email}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F7F7F7",
  },
  top: {
    height: 74,
    backgroundColor: "#EAEBF3",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 12,
    paddingRight: 16,
    position: "sticky",
    top: TOP_BAR_HEIGHT_MOBILE,
    zIndex: 99,
  },
  closeAndTitle: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.12,
    paddingLeft: 10,
  },
  button: {
    minWidth: "fit-content",
  },
  content: {
    padding: "0 16px",
  },
  row: {
    width: "100%",
    maxWidth: "100%",
    height: 56,
    borderBottom: "1px solid #EAEBF3",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    padding: "0 16px 0 6px",
  },
  lastRow: {
    borderBottom: "none !important",
  },
  checked: {
    color: `${theme.palette.secondary2.main} !important`,
  },
  nameAndEmail: {
    overflow: "hidden",
    paddingLeft: 6,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "22px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  disable: {
    color: theme.palette.text.secondary2,
  },
  email: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));
