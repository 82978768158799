// cSpell:ignore Swipeable
import { useState, Fragment, useEffect, useRef, useCallback } from "react";
import {
  IconButton,
  makeStyles,
  Button,
  SwipeableDrawer,
  TextField,
} from "@material-ui/core";
import { isIOS } from "react-device-detect";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import CircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Form } from "react-final-form";
import { FormApi } from "final-form";
import { ButtonSpinner, CircularLoader } from "..";
import { GenericObject } from "../../types";
import { contactActions, getCampaignId, setToast } from "../../state";
import { useAppDispatch } from "../../types/state";
import { useIsMobile } from "../../hooks/ui";
import { IS_RN_WEBVIEW } from "../../../lib";
import classNames from "classnames";

enum Modes {
  select = "select",
  multiples = "multiples",
}

type Contact = {
  name: string;
  selected?: boolean;
  emails: string[];
  email?: string | null;
  phone?: string | null;
  phoneNumbers: string[];
  hasMultiples?: boolean;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function ImportContacts({ isOpen, onClose: _onClose }: Props) {
  const dispatch = useAppDispatch();
  const classes = styles();
  const [mode, setMode] = useState<Modes>(Modes.select);
  const [loaded, setLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState<{ contacts: Contact[] }>({
    contacts: [],
  });
  const [search, setSearch] = useState("");
  const [searchTrigger, setSearchTrigger] = useState<RegExp>(new RegExp(""));
  const [showMissingErrors, setShowMissingErrors] = useState(false);
  const isMobile = useIsMobile();
  const campaign_id = useSelector(getCampaignId);
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (!IS_RN_WEBVIEW) return;
    if (isIOS) {
      window.addEventListener("message", listenForContacts);
    } else {
      document.addEventListener("message", listenForContacts);
    }

    return () => {
      if (isIOS) {
        window.removeEventListener("message", listenForContacts);
      } else {
        document.removeEventListener("message", listenForContacts);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen && (!isMobile || !IS_RN_WEBVIEW)) onClose();
    else {
      if (isOpen && IS_RN_WEBVIEW && (window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(
          JSON.stringify({ getContacts: true }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isMobile, IS_RN_WEBVIEW]);

  const onClose = () => {
    _onClose();
    setLoaded(false);
    setShowMissingErrors(false);
    setInitialValues({ contacts: [] });
    setMode(Modes.select);
  };

  const listenForContacts = (event: Event) => {
    try {
      if (event instanceof MessageEvent) {
        if (typeof event.data !== "string") return;
        const data = JSON.parse(event.data);
        if (data) {
          const { contacts } = data;
          if (contacts) {
            setInitialValues({ contacts });
            setLoaded(true);
          }
        }
      }
    } catch (_e) {
      const e = _e as any;
      const errMsg =
        e && e.message
          ? e.message
          : "An error occurred accessing your phone contacts";
      dispatch(setToast(errMsg));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(
    debounce(
      (newVal: string) => setSearchTrigger(new RegExp(newVal, "gi")),
      300,
    ),
    [],
  );

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedHandleSearch(target.value);
  };

  const clearSearch = () => {
    setSearch("");
    setSearchTrigger(new RegExp(""));
  };

  const goBack = () => {
    setMode(Modes.select);
    setShowMissingErrors(false);
    scrollToTop();
  };

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };

  const onSubmit = async (
    { contacts }: { contacts: Contact[] },
    _: any,
    complete: any,
  ) => {
    if (!Array.isArray(contacts) || !contacts.length) {
      dispatch(setToast("You have not selected any contacts"));
      complete();
      return;
    }

    const selectedContacts: GenericObject[] = [];
    let hasUnchosenMultiples = false;
    // since this is the most common scenario that they will have multiples on the first round - we are using a `some` to return when it hits the first match
    const hasSelectedMultiples = contacts.some(c => {
      const {
        selected,
        name,
        email,
        phone,
        emails,
        phoneNumbers,
        hasMultiples,
      } = c;
      if (mode === Modes.select && selected && hasMultiples) return true;
      if (
        mode === Modes.multiples &&
        selected &&
        ((!email && emails.length > 1) || (!phone && phoneNumbers.length > 1))
      ) {
        hasUnchosenMultiples = true;
      }
      if (selected && (email || phone)) {
        selectedContacts.push({
          email: email ? email : null,
          phone: phone ? phone : null,
          name,
        });
      }
    });
    if (hasSelectedMultiples && mode === Modes.select) {
      setMode(Modes.multiples);
      scrollToTop();
      complete();
      return;
    }
    if (hasUnchosenMultiples) {
      dispatch(
        setToast(
          "Please select a phone number or email address for each contact before saving.",
        ),
      );
      setShowMissingErrors(true);
      complete();
      return;
    }
    if (!selectedContacts.length) {
      dispatch(setToast("You have not selected any contacts"));
      complete();
      return;
    }

    const success = await dispatch(
      contactActions.addContacts(
        { contacts: selectedContacts, campaign_id },
        selectedContacts.length,
      ),
    );
    if (!success && mode === Modes.multiples) {
      setMode(Modes.select);
      scrollToTop();
    } else if (success) onClose();
    complete();
  };

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={onClose}
      onOpen={() => {}}
      classes={{ paper: classes.drawer }}
      transitionDuration={500}
      anchor="bottom"
    >
      <div className={classes.container} ref={containerRef}>
        <Form
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, form, values }) => {
            return (
              <Fragment>
                <div className={classes.topContent}>
                  <div className={classes.titleRow}>
                    <div className={classes.titleAndBack}>
                      {mode === Modes.multiples && (
                        <IconButton
                          size="small"
                          onClick={goBack}
                          className={classes.back}
                        >
                          <ChevronLeftIcon />
                        </IconButton>
                      )}
                      <h1 className={classes.title}>Import contacts</h1>
                    </div>

                    <IconButton
                      size="small"
                      onClick={onClose}
                      className={classes.close}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  {mode === Modes.select && (
                    <Fragment>
                      <div className={classes.inputWrapper}>
                        <TextField
                          placeholder="Search"
                          value={search}
                          size="small"
                          onChange={onSearchChange}
                          className={classes.input}
                          fullWidth
                          InputProps={{
                            startAdornment: search ? (
                              undefined
                            ) : (
                              <SearchIcon className={classes.searchIcon} />
                            ),
                            endAdornment: !search ? (
                              undefined
                            ) : (
                              <CloseIcon
                                className={classes.clearIcon}
                                onClick={clearSearch}
                              />
                            ),
                          }}
                        />
                      </div>

                      <SelectedContacts values={values} form={form} />
                    </Fragment>
                  )}
                </div>

                {mode === Modes.multiples && (
                  <div className={classes.multipleMsg}>
                    We found multiple phone numbers or email addresses for some
                    of your contacts. Tell us which ones to use for the contacts
                    listed below.
                  </div>
                )}

                {!loaded && <CircularLoader show />}

                <form onSubmit={handleSubmit} className={classes.form}>
                  <div>
                    <FieldArray name="contacts">
                      {({ fields }) => (
                        <Fragment>
                          {fields.map((field, index) => {
                            const values = fields.value[index];
                            return (
                              <Row
                                key={index}
                                field={field}
                                values={values}
                                form={form}
                                mode={mode}
                                searchTrigger={searchTrigger}
                                showMissingErrors={showMissingErrors}
                              />
                            );
                          })}
                        </Fragment>
                      )}
                    </FieldArray>
                  </div>

                  <div className={classes.bottom}>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      className={classes.button}
                      disabled={submitting}
                    >
                      {mode === Modes.multiples ? "Save" : "Import"}
                      <ButtonSpinner show={submitting} />
                    </Button>
                  </div>
                </form>
              </Fragment>
            );
          }}
        />
      </div>
    </SwipeableDrawer>
  );
}

type RowProps = {
  field: string;
  values: Contact;
  form: FormApi<any>;
  mode: Modes;
  searchTrigger: RegExp;
  showMissingErrors: boolean;
};
function Row({
  field,
  values,
  form,
  mode,
  searchTrigger,
  showMissingErrors,
}: RowProps) {
  const classes = styles();
  const {
    name,
    selected = false,
    emails,
    phoneNumbers,
    phone,
    email,
    hasMultiples,
  } = values;
  const [hasEmailErrors, setHasEmailErrors] = useState(false);
  const [hasPhoneErrors, setHasPhoneErrors] = useState(false);

  useEffect(() => {
    let _hasMultiples = false;
    if (emails.length === 1) form.change(`${field}.email`, emails[0]);
    else if (emails.length) _hasMultiples = true;

    if (phoneNumbers.length === 1) {
      form.change(`${field}.phone`, phoneNumbers[0]);
    } else if (phoneNumbers.length) _hasMultiples = true;

    if (_hasMultiples) {
      form.change(`${field}.hasMultiples`, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails, phoneNumbers]);

  useEffect(() => {
    if (!hasMultiples) return;
    if (!showMissingErrors) {
      setHasEmailErrors(false);
      setHasPhoneErrors(false);
      return;
    }
    if (!email && emails.length > 1) setHasEmailErrors(true);
    else setHasEmailErrors(false);
    if (!phone && phoneNumbers.length > 1) setHasPhoneErrors(true);
    else setHasPhoneErrors(false);
  }, [showMissingErrors, hasMultiples, emails, phoneNumbers, email, phone]);

  const toggleSelect = () => form.change(`${field}.selected`, !selected);

  if (mode === Modes.select && searchTrigger && !searchTrigger.test(name)) {
    return <></>;
  } else if (mode === Modes.multiples && (!selected || !hasMultiples)) {
    return <></>;
  } else if (mode === Modes.multiples) {
    return (
      <div className={classes.mRow}>
        <div className={classes.name}>{name}</div>
        {phoneNumbers.length > 1 &&
          phoneNumbers.map((p, i) => {
            return (
              <div key={i} className={classes.mFigureWrapper}>
                <div className={classes.mCheckWrapper}>
                  {phone === p ? (
                    <CheckCircleIcon
                      className={classes.checkedIcon}
                      onClick={() => form.change(`${field}.phone`, null)}
                    />
                  ) : (
                    <CircleIcon
                      className={classNames(
                        classes.unCheckedIcon,
                        hasPhoneErrors && classes.error,
                      )}
                      onClick={() => form.change(`${field}.phone`, p)}
                    />
                  )}
                </div>
                <div
                  key={i}
                  className={classNames(
                    classes.mFigure,
                    hasPhoneErrors && classes.error,
                  )}
                >
                  {p}
                </div>
              </div>
            );
          })}
        {hasPhoneErrors && (
          <div className={classes.errorMsg}>
            <ErrorIcon color="error" className={classes.errorIcon} />
            Please make a selection
          </div>
        )}
        {emails.length > 1 && phoneNumbers.length > 1 && (
          <div className={classes.divider} />
        )}
        {emails.length > 1 &&
          emails.map((e, i) => {
            return (
              <div key={i} className={classes.mFigureWrapper}>
                <div className={classes.mCheckWrapper}>
                  {email === e ? (
                    <CheckCircleIcon
                      className={classes.checkedIcon}
                      onClick={() => form.change(`${field}.email`, null)}
                    />
                  ) : (
                    <CircleIcon
                      className={classNames(
                        classes.unCheckedIcon,
                        hasEmailErrors && classes.error,
                      )}
                      onClick={() => form.change(`${field}.email`, e)}
                    />
                  )}
                </div>
                <div
                  key={i}
                  className={classNames(
                    classes.mFigure,
                    hasEmailErrors && classes.error,
                  )}
                >
                  {e}
                </div>
              </div>
            );
          })}
        {hasEmailErrors && (
          <div className={classes.errorMsg}>
            <ErrorIcon color="error" className={classes.errorIcon} />
            Please make a selection
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={classes.row}>
      <div className={classes.checkWrapper} onClick={toggleSelect}>
        {selected ? (
          <CheckCircleIcon className={classes.checkedIcon} />
        ) : (
          <CircleIcon className={classes.unCheckedIcon} />
        )}
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.name}>{name}</div>
        <div className={classes.contactInfo}>
          {phoneNumbers.map((p: string, i: number) => {
            const noDot = i === phoneNumbers.length - 1 && !emails.length;
            return (
              <span key={i}>
                <span>{p}</span>
                {!noDot && <span className={classes.dot}>•</span>}
              </span>
            );
          })}
          {emails.map((e: string, i: number) => {
            const noDot = i === emails.length - 1;
            return (
              <span key={i}>
                <span>{e}</span>
                {!noDot && <span className={classes.dot}>•</span>}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
}

type SelectedContactsProps = {
  values: { contacts: Contact[] };
  form: FormApi<any>;
};
function SelectedContacts({
  values: { contacts },
  form,
}: SelectedContactsProps) {
  const classes = styles();
  const [selectedContacts, setSelectedContacts] = useState<
    { index: number; name: string }[]
  >([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const SC: { index: number; name: string }[] = [];
    contacts.forEach(({ name, selected }, index) => {
      if (selected) SC.push({ index, name });
    });
    setSelectedContacts(SC);
  }, [contacts]);

  if (!selectedContacts.length) return <></>;
  return (
    <div className={classes.sContainer}>
      {!showMore && (
        <div className={classes.sStr}>
          <div className={classes.sFirst}>{selectedContacts[0].name}</div>
          {selectedContacts.length > 1 &&
            `& ${selectedContacts.length - 1} more`}
        </div>
      )}
      {showMore && (
        <div className={classes.chips}>
          {selectedContacts.map(({ name, index }) => {
            return (
              <div key={index} className={classes.chip}>
                <div className={classes.chipName}>{name}</div>

                <CancelIcon
                  className={classes.remove}
                  fontSize="small"
                  onClick={() =>
                    form.change(`contacts[${index}].selected`, false)
                  }
                />
              </div>
            );
          })}
        </div>
      )}
      <div className={classes.expandWrapper}>
        <IconButton
          size="small"
          className={classes.expand}
          onClick={() => setShowMore(prev => !prev)}
        >
          {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  drawer: {
    top: 75,
    borderRadius: "20px 20px 0 0",
    maxHeight: "unset",
  },
  container: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
  },
  topContent: {
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: 16,
  },
  titleAndBack: {
    display: "flex",
    alignItems: "center",
  },
  back: {
    marginRight: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
  },
  close: {
    marginTop: -8,
    marginRight: -8,
  },
  inputWrapper: {
    padding: "0 16px 16px 16px",
  },
  input: {
    borderRadius: 4,
    backgroundColor: "#EAEBF3",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  searchIcon: {
    color: theme.palette.text.secondary2,
    marginRight: 8,
  },
  clearIcon: {
    color: theme.palette.text.secondary2,
  },
  multipleMsg: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "22px",
    padding: "0 16px 16px 16px",
  },
  form: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #EAEBF3",
    height: 58,
    margin: "0 16px",
    maxWidth: "100%",
  },
  checkWrapper: {
    padding: "0 24px 0 10px",
  },
  unCheckedIcon: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  checkedIcon: {
    cursor: "pointer",
    color: "#1AC846",
  },
  infoWrapper: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "22px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  contactInfo: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  dot: {
    color: theme.palette.primary.main,
    padding: "0 6px",
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
  },
  // MULTI ROW
  mRow: {
    borderBottom: "1px solid #C9CDDE",
    padding: "12px 16px 0 16px",
  },
  mFigureWrapper: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 14,
    letterSpacing: 0.1,
    padding: "10px 0",
  },
  mFigure: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
  },
  mCheckWrapper: {
    padding: "0 10px",
  },
  divider: {
    width: "100%",
    borderBottom: "1px solid #EAEBF3",
  },
  error: {
    color: theme.palette.error.main,
  },
  errorMsg: {
    color: theme.palette.error.main,
    marginTop: 6,
    marginBottom: 16,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    marginRight: 10,
  },
  // SELECTED
  sContainer: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderBottom: "1px solid #EAEBF3",
    flex: 1,
    padding: "0 16px 8px 16px",
    marginBottom: 16,
  },
  sFirst: {
    maxWidth: "calc(100% - 80px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 4,
  },
  sStr: {
    display: "flex",
    flex: 1,
    maxWidth: "calc(100% - 30px)",
  },
  expandWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: 30,
  },
  expand: {
    marginRight: -6,
    marginTop: -6,
  },
  chips: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    height: 24,
    maxWidth: 165,
    width: "fit-content",
    borderRadius: 12,
    backgroundColor: "#E0E0E0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    marginBottom: 8,
    paddingLeft: 8,
    paddingRight: 4,
  },
  chipName: {
    overflow: "hidden",
    fontSize: 13,
    letterSpacing: 0.15,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  remove: {
    marginLeft: 2,
    color: theme.palette.text.secondary2,
  },
  // BOTTOM
  bottom: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  button: {
    margin: "16px 0",
    width: 300,
  },
}));
