import { Fragment } from "react";
import {
  makeStyles,
  CircularProgress,
  CircularProgressProps,
  Theme,
} from "@material-ui/core";

type Props = {
  show: boolean;
  size?: number;
  color?: CircularProgressProps["color"];
  isRedButton?: boolean;
  customColor?: string;
};

export function ButtonSpinner({
  show,
  size = 24,
  color = "primary",
  isRedButton,
  customColor,
}: Props) {
  const classes = styles({ isRedButton, customColor });

  return (
    <Fragment>
      {show && (
        <CircularProgress
          color={color}
          className={classes.spinner}
          size={size}
        />
      )}
    </Fragment>
  );
}

type StyleProps = {
  isRedButton?: boolean;
  customColor?: string;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  spinner: {
    position: "absolute",
    color: ({ isRedButton, customColor }) =>
      isRedButton ? theme.palette.error.main : customColor ? customColor : "",
  },
}));
