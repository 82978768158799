import { Theme, makeStyles } from "@material-ui/core";
import QRCode from "qrcode.react";
import { useSelector } from "react-redux";
import { AFFILIATE_SUB_CODES, AFFILIATES } from "../../../lib";
import { RootState } from "../../types/state";
import { affiliateLinkWithTS, downloadQRCode } from "../../lib";
import { getIsOrgOrEmp } from "../../../customState";
const EL_ID = "qr-code-link";

type Props = {
  noBorderRadius?: boolean;
};

export function WebpageQR({ noBorderRadius }: Props) {
  const classes = styles({ noBorderRadius });
  const { webpageLink } = useSelector((state: RootState) => state.campaign);
  const isOrg = useSelector(getIsOrgOrEmp);
  const link = affiliateLinkWithTS(
    webpageLink,
    AFFILIATES.DASHBOARD_SHARING_LINKS,
    AFFILIATE_SUB_CODES.QR,
  );

  return (
    <div className={classes.qrBox}>
      {isOrg && (
        <div className={classes.qrTitle}>
          <div>Scan for your</div>
          <div>campaign page</div>
        </div>
      )}
      {!isOrg && (
        <div className={classes.qrTitle}>
          Scan for your personal fundraising page
        </div>
      )}

      <QRCode id={EL_ID} value={link} includeMargin={false} />

      <div className={classes.qrShare}>
        <span
          className={classes.qrDownload}
          onClick={() => downloadQRCode(EL_ID)}
        >
          Download
        </span>{" "}
        & share
      </div>
    </div>
  );
}

type StyleProps = {
  noBorderRadius?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  qrBox: {
    width: "100%",
    maxWidth: "100%",
    borderRadius: ({ noBorderRadius }) => (noBorderRadius ? 0 : 8),
    padding: "14px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    background:
      "linear-gradient(227.18deg, rgba(227,242,254,0.07) 0%, #D8ECFB 100%, #D8ECFB 100%)",
  },
  qrTitle: {
    maxWidth: "100%",
    textWrap: "wrap",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "18px",
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: 19,
  },
  qrDownload: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "24px",
    color: theme.palette.primary.main,
    marginRight: 6,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
  },
  qrShare: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    marginTop: 13,
  },
}));
