import { Button } from "@material-ui/core";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { updateProfile as updateProfileAction } from "../../customState";
import { TextInput } from "../../components";
import {
  APIClient,
  generateAuthHeader,
  required,
  email as emailValidation,
  composeValidators,
  isRep,
} from "../../lib";
import { useNotify, usePermissions } from "react-admin";
import { styles } from "./Profile.styles";
import SaveIcon from "@material-ui/icons/Save";

function _UpdateProfile(props) {
  const {
    profile: { phone, email },
    updateProfile,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const { permissions: { role } = {} } = usePermissions();

  const onSubmit = async values => {
    const res = await APIClient.put("/users/profile", values, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) {
      return notify(errorMessage, "warning");
    }
    updateProfile(data);
    notify("your profile has been successfully updated", { type: "info" });
  };

  return (
    <div className={classes.tabContainer}>
      <Form
        initialValues={{ phone, email }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextInput
                name="email"
                label="Email"
                validation={composeValidators(required, emailValidation)}
              />
              {isRep(role) && (
                <TextInput name="phone" label="Phone" validation={required} />
              )}

              <Button
                type="submit"
                disabled={submitting}
                className={classes.button}
              >
                <SaveIcon className={classes.saveIcon} />
                update
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
}

export const UpdateProfile = connect(
  state => {
    const { profile } = state;
    return { profile };
  },
  { updateProfile: updateProfileAction },
)(_UpdateProfile);
