import { useEffect, useState, FC, useRef } from "react";
import queryString from "query-string";
import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  contactActions,
  getCampaignId,
  setToast,
  setTopBarTitleComponent,
  setVolunteerSetupData,
} from "../../state";
import {
  APIRes,
  GuidedSetupScreen,
  GuidedSetupScreens,
  ToastTypes,
  GuidedSetupScreenIds,
  fromMenuQueyParam,
  paths,
} from "../../types";
import { APIAuthClient, scrollToTop } from "../../lib";
import { Paragraph } from "./screens/Paragraph";
import { Class } from "./screens/Class";
import { Goal } from "./screens/Goal";
import { Complete } from "./screens/Complete";
import { Photo } from "./screens/Photo";
import { Start } from "./screens/Start";
import { Team } from "./screens/Team";
import { Sharing } from "./screens/Sharing";
import { TextOptIn } from "./screens/TextOptIn";
import { Prizes } from "./screens/Prizes";
import { FamilyMembers } from "./screens/FamilyMembers";
import { TextContacts } from "./screens/TextContacts";
import { Contacts } from "./screens/Contacts";
import { CircularLoader } from "../../components/ui/CircularLoader";
import { useAppDispatch } from "../../types/state";
import { Navigation } from "./components/Navigation";
import { useIsMobile } from "../../hooks/ui";
import { Events } from "./screens/Events";
import { RequiredPrizeSelection } from "./components/RequiredPrizeSelection";
const screenMapping = {
  PICTURE: Photo,
  GOAL: Goal,
  PARAGRAPH: Paragraph,
  CLASS: Class,
  TEAM: Team,
  FAMILY_MEMBERS: FamilyMembers,
  TEXT_AGREEMENT: TextOptIn,
  PRIZE_PICKS: Prizes,
  CONTACTS: Contacts,
  TEXT_CONTACTS: TextContacts,
  EVENTS: Events,
  SOCIAL: Sharing,
};

export function VolunteerGuidedSetup() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const campaignId = useSelector(getCampaignId);
  const noPromptsRef = useRef(false);
  const { [fromMenuQueyParam]: from_menu } = queryString.parse(location.search);
  const fromMenu = from_menu === "true";
  const [loading, setLoading] = useState(true);
  const [screens, setScreens] = useState<GuidedSetupScreens[]>([]);
  const [showStartScreen, setShowStartScreen] = useState(false);
  const [showCompleteScreen, setShowCompleteScreen] = useState(false);
  const [activeScreenIndex, setActiveScreenIndex] = useState(0);
  const [activeScreen, setActiveScreen] = useState<GuidedSetupScreens>();
  const [
    nextTriggeredBy,
    setNextTriggeredBy,
  ] = useState<GuidedSetupScreens | null>(null);
  const [disableNext, setDisableNext] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showRequiredPrizeSelection, setShowRequiredPrizeSelection] = useState(
    false,
  );
  const classes = styles();

  useEffect(() => {
    const component = <h1 className={classes.title}>Campaign Setup</h1>;
    dispatch(setTopBarTitleComponent(component));
    return () => {
      dispatch(setTopBarTitleComponent(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIAuthClient.get<any, APIRes>(
        `/volunteer_setup/data?campaign_id=${campaignId}`,
      );

      const { error, errorMessage, data } = res;
      if (error) {
        dispatch(setToast(errorMessage, ToastTypes.warn));
        return;
      }
      // if it's the initial setup; check if this campaign doesn't use the initial setup
      const {
        setup_completed,
        no_initial_volunteer_setup,
        missingRequiredPrizeSelection,
      } = data;
      noPromptsRef.current =
        !fromMenu && !setup_completed && no_initial_volunteer_setup;
      if (!missingRequiredPrizeSelection && noPromptsRef.current) {
        history.replace(paths.HOME);
      } else {
        if (missingRequiredPrizeSelection) {
          setShowRequiredPrizeSelection(true);
        }
        dispatch(setVolunteerSetupData(data));
        getScreenOptions(
          data,
          fromMenu,
          setScreens,
          setLoading,
          setShowStartScreen,
        );
      }
    };
    if (campaignId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (screens.length) {
      setActiveScreen(screens[0]);
      if (screens.includes("TEXT_CONTACTS") || screens.includes("CONTACTS")) {
        dispatch(contactActions.fetchContacts());
      }
      if (screens.includes("CONTACTS")) {
        dispatch(contactActions.fetchConvertibleDonors());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screens]);

  useEffect(() => {
    if (activeScreen) scrollToTop();
  }, [activeScreen]);

  const handleNextTriggered = (activeScreen: GuidedSetupScreens) => {
    setNextTriggeredBy(activeScreen);
  };

  const handleNext = () => {
    setActiveScreenIndex(currIndex => {
      const nextIndex = currIndex + 1;
      if (nextIndex > screens.length - 1) {
        setActiveScreen(undefined);
        setShowCompleteScreen(true);
        return currIndex;
      }
      const _screen = screens[nextIndex];
      if (_screen) {
        setActiveScreen(_screen);
        return nextIndex;
      } else return currIndex;
    });
    setTimeout(() => setSubmitting(false), 200);
  };

  const handleBack = () => {
    setActiveScreenIndex(currIndex => {
      const prevIndex = currIndex - 1;
      const _screen = screens[prevIndex];
      if (_screen) {
        setActiveScreen(_screen);
        return prevIndex;
      } else return currIndex;
    });
  };

  const closeRequiredPrizeSelection = () => {
    setShowRequiredPrizeSelection(false);
    if (noPromptsRef.current) history.replace(paths.HOME);
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularLoader show={true} />
      </div>
    );
  } else if (showRequiredPrizeSelection) {
    return <RequiredPrizeSelection onClose={closeRequiredPrizeSelection} />;
  } else if (showStartScreen) {
    return <Start setShowStartScreen={setShowStartScreen} />;
  } else if (showCompleteScreen) {
    return <Complete />;
  }
  return (
    <div className={classes.wrapper}>
      {isMobile && (
        <div className={classes.close}>
          <IconButton size="small" onClick={() => history.push(paths.HOME)}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <div className={classes.container}>
        {screens.map((s, index) => {
          if (!s) return null;
          const Screen: FC<GuidedSetupScreen> = screenMapping[s];
          if (!Screen) return null;
          return (
            <Screen
              key={index}
              activeScreen={activeScreen}
              setDisableNext={setDisableNext}
              handleNext={handleNext}
              setSubmitting={setSubmitting}
              nextTriggeredBy={nextTriggeredBy}
              setNextTriggeredBy={setNextTriggeredBy}
            />
          );
        })}
      </div>

      <Navigation
        activeScreen={activeScreen}
        handleNextTriggered={handleNextTriggered}
        submitting={submitting}
        disableNext={disableNext}
        activeScreenIndex={activeScreenIndex}
        handleBack={handleBack}
        handleNext={handleNext}
        screens={screens}
      />
    </div>
  );
}

function getScreenOptions(
  data: any,
  fromMenu: boolean,
  setScreens: React.Dispatch<React.SetStateAction<GuidedSetupScreens[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setShowStartScreen: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const {
    initial_volunteer_setup_screen_ids: SSI,
    setup_completed,
    hasTeams,
    team_id,
    hasVolunteerTexting,
    agreed_to_receive_texts,
    class_name,
    campaignEventGroupCount,
    allow_family_accounts,
    allowStudentPrizePicks,
    no_volunteer_pictures,
    class_options: classOptions,
    no_volunteer_display_html_edits,
  } = data;

  let potentialScreens: GuidedSetupScreens[] = [];
  // if it's an initial setup & the campaign has selected screen IDs to show
  if (!fromMenu && Array.isArray(SSI) && SSI.length && !setup_completed) {
    SSI.forEach(id => {
      if (GuidedSetupScreenIds[id]) {
        potentialScreens.push(GuidedSetupScreenIds[id]);
      }
    });
  } else {
    potentialScreens = Object.values(GuidedSetupScreenIds);
  }

  const screens: GuidedSetupScreens[] = [];
  potentialScreens.forEach(screen => {
    switch (screen) {
      case "PICTURE":
        if (!no_volunteer_pictures) screens.push(screen);
        break;
      case "PARAGRAPH":
        if (!no_volunteer_display_html_edits) screens.push(screen);
        break;
      case "CLASS":
        if (!class_name && Array.isArray(classOptions) && classOptions.length) {
          screens.push(screen);
        }
        break;
      case "TEAM":
        if (hasTeams && !team_id) screens.push(screen);
        break;
      case "FAMILY_MEMBERS":
        if (allow_family_accounts) screens.push(screen);
        break;
      case "TEXT_AGREEMENT":
        if (hasVolunteerTexting && !agreed_to_receive_texts) {
          screens.push(screen);
        }
        break;
      case "PRIZE_PICKS":
        if (allowStudentPrizePicks) screens.push(screen);
        break;
      case "EVENTS":
        if (campaignEventGroupCount) screens.push(screen);
        break;
      default:
        screens.push(screen);
    }
  });
  setScreens(screens);
  if (!setup_completed) setShowStartScreen(true);
  // only show page after screen options are set
  setTimeout(() => setLoading(false), 50);
}

const styles = makeStyles(_theme => ({
  wrapper: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 24,
    minHeight: "100%",
    position: "relative",
  },
  close: {
    position: "absolute",
    top: 12,
    right: 12,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
  },
  loader: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
}));
