import { profileTypes } from "./actions";

const initialState = {};

const profileReducers = (state = initialState, action) => {
  switch (action.type) {
    case profileTypes.PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case profileTypes.PROFILE_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case profileTypes.CLEAR_PROFILE:
      return {};
    default:
      return state;
  }
};

export { profileReducers };
