import { isIOS, osName } from "react-device-detect";
import {
  AFFILIATE_SC_PARAM,
  AFFILIATE_SUB_CODES,
  IS_RN_WEBVIEW,
  store,
} from "../../lib";
import { setToast } from "../state";
const SCP = AFFILIATE_SC_PARAM;

export function facebookLink(webpageLink: string): string {
  const link = encodeURIComponent(
    `${webpageLink}&${SCP}=${AFFILIATE_SUB_CODES.FACEBOOK}`,
  );
  return `https://www.facebook.com/sharer.php?u=${link}`;
}

export function linkedinLink(webpageLink: string): string {
  const link = encodeURIComponent(
    `${webpageLink}&${SCP}=${AFFILIATE_SUB_CODES.LINKEDIN}`,
  );
  return `https://www.linkedin.com/shareArticle?url=${link}`;
}

export function whatsappLink(
  webpageLink: string,
  orgName: string,
  isVolunteer?: boolean,
): string {
  const link = `${webpageLink}&${SCP}=${AFFILIATE_SUB_CODES.WHATSAPP}`;
  const txt = prefilledTxt(orgName, link, isVolunteer);
  if (IS_RN_WEBVIEW) {
    return `whatsapp://send/?text=${txt}`;
  }
  return `https://wa.me/?text=${txt}`;
}

export function twitterLink(
  webpageLink: string,
  orgName: string,
  isVolunteer?: boolean,
): string {
  const link = encodeURIComponent(
    `${webpageLink}&${SCP}=${AFFILIATE_SUB_CODES.TWITTER}`,
  );
  const txt = prefilledTxt(orgName, "", isVolunteer, false);
  return `https://twitter.com/intent/tweet?url=${link}&text=${txt}`;
}

export function textMessageLink(
  webpageLink: string,
  orgName: string,
  isVolunteer?: boolean,
  phone?: string | null,
  noAffiliateSubCode = false,
): string {
  const link = !noAffiliateSubCode
    ? `${webpageLink}&${SCP}=${AFFILIATE_SUB_CODES.TEXT}`
    : webpageLink;
  const body = prefilledTxt(orgName, link, isVolunteer);
  return isIOS || osName === "Mac OS"
    ? `sms:${phone ? phone : ""}&body=${body}`
    : `sms://${phone ? phone : ""}?body=${body}`;
  // : `sms:${phone ? phone : "?"}&body=${body}`;
}

export function emailLink(
  webpageLink: string,
  orgName: string,
  isVolunteer?: boolean,
  volunteerName?: string,
): string {
  const link = `${webpageLink}&${SCP}=${AFFILIATE_SUB_CODES.EMAIL}`;
  const breakStr = isIOS ? `  ` : `\n\n`;
  let subject = isVolunteer
    ? `I am raising funds for ${orgName}`
    : `${orgName} is raising funds`;
  subject = encodeURIComponent(subject);
  let body: string;
  if (isVolunteer) {
    body = `Hello,${breakStr}It's ${volunteerName} here.${breakStr}I am raising funds for ${orgName}.${breakStr}Please take a moment to view my personal fundraising page and consider supporting!${breakStr}${link}${breakStr}Thank you very much,${breakStr}${volunteerName}`;
  } else {
    body = `Hello,${breakStr}${orgName} is raising funds.${breakStr}Please take a moment to view their fundraising page and consider supporting!${breakStr}${link}${breakStr}Thank you very much,${breakStr}${orgName}`;
  }
  body = encodeURIComponent(body);
  return `mailto:?subject=${subject}&body=${body}`;
}

export function downloadQRCode(elId: string) {
  const canvas = document.getElementById(elId) as HTMLCanvasElement;
  if (!canvas) return;
  if (IS_RN_WEBVIEW) {
    const b64 = canvas
      .toDataURL("image/png")
      .replace("data:image/png;base64,", "");
    if (!(window as any).ReactNativeWebView) {
      store.dispatch(setToast("There was an error downloading your file"));
    } else {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          isDownload: true,
          file: b64,
          fileName: "webpage-link.png",
          fileType: "image/png",
          isBase64: true,
        }),
      );
    }
  } else {
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "webpage-link.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}

function prefilledTxt(
  orgName: string,
  webpageLink: string,
  isVolunteer: boolean | undefined,
  withUrl = true,
): string {
  let text: string;
  if (isVolunteer) {
    text = `HI! I'm raising funds for ${orgName}.\nPlease check out my personal page and join me with your support!`;
  } else {
    text = `HI! ${orgName} is raising funds.\nPlease consider supporting!`;
  }
  if (withUrl) text = `${text}\n${webpageLink}\nThank you`;
  return encodeURIComponent(text);
}
