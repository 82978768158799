import { makeStyles, Button, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Contacts2SVG } from "../../../assets/Contacts2SVG";

type Props = {
  setShowAdd: React.Dispatch<React.SetStateAction<boolean>>;
  hasSearch: boolean;
};

export function NoContacts({ setShowAdd, hasSearch }: Props) {
  const classes = styles({ hasSearch });
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Contacts2SVG />
        <h2 className={classes.txt}>
          {hasSearch ? "No search results" : "No contacts yet"}
        </h2>
        {!hasSearch && (
          <Button
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => setShowAdd(true)}
          >
            Add contact
          </Button>
        )}
      </div>
    </div>
  );
}

type StyleProps = {
  hasSearch: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexGrow: 1,
    paddingTop: 24,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 16,
    },
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 63,
    backgroundColor: ({ hasSearch }) => (hasSearch ? "none" : "#FFFFFF"),
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
    },
  },
  txt: {
    paddingTop: 24,
    paddingBottom: 32,
    fontSize: 20,
    letterSpacing: 0.15,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 24,
    },
  },
  button: {
    width: 200,
    borderRadius: 18,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "95%",
    },
  },
}));
