import { useState, useEffect, Fragment } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import CountdownComp from "react-countdown-now";
import { format, isFuture, isPast } from "date-fns";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { HourGlassSVG } from "../../../assets/HourGlassSVG";
import { ConfettiSVG } from "../../../assets/ConfettiSVG";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { affiliateLinkWithTS } from "../../../lib";
import { AFFILIATE_SUB_CODES, AFFILIATES } from "../../../../lib";
import InviteIcon from "@material-ui/icons/GroupAddOutlined";
import { ShareIconSVG } from "../../../assets/ShareIconSVG";
import classNames from "classnames";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import { paths } from "../../../types";
import { getVolunteerLabel } from "../../../state";
import { LinksDrawer } from "./LinksDrawer";
import { ShareCardView } from "../../../types/dashboard";
import Skeleton from "@material-ui/lab/Skeleton";
enum Modes {
  none = "none",
  countdown = "countdown",
  startingSoon = "startingSoon",
  ended = "ended",
}

export function OrgHeader() {
  const classes = styles();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const [mode, setMode] = useState<Modes>(Modes.none);
  const { campaignId, start_date, end_date, webpageLink } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const link = affiliateLinkWithTS(
    webpageLink,
    AFFILIATES.DASHBOARD_SHARING_LINKS,
    AFFILIATE_SUB_CODES.LINK,
  );
  const userName = useSelector(({ user }: RootState) => user.userName);

  useEffect(() => {
    if (start_date && isFuture(new Date(start_date))) {
      setMode(Modes.startingSoon);
    } else if (end_date && isPast(new Date(end_date))) {
      setMode(Modes.ended);
    } else if (end_date && isFuture(new Date(end_date))) {
      setMode(Modes.countdown);
    }
  }, [start_date, end_date]);

  if (mode === Modes.none && !isMobile) {
    return <Skeleton variant="rect" className={classes.skeleton} />;
  }
  return (
    <div className={classes.container}>
      {/* MOBILE */}
      {isMobile && (
        <Fragment>
          <div className={classes.gradientBG} />
          <div className={classes.content}>
            <div>
              <div className={classes.greeting}>Hi, {userName || ""}!</div>
              <div className={classes.campaignId}>
                Campaign ID {campaignId || ""}
              </div>
            </div>
            <Button
              size="small"
              variant="text"
              color="primary"
              endIcon={<OpenInNewIcon />}
              className={classes.button}
              href={link}
              target="_blank"
            >
              VIEW CAMPAIGN
            </Button>
          </div>
          {mode === Modes.startingSoon && start_date && (
            <StartingSoon start_date={start_date} />
          )}
          {mode === Modes.ended && <Ended />}
          <Links view={ShareCardView.orgSignUp} />
          <Links view={ShareCardView.orgShare} />
        </Fragment>
      )}

      {/* DESKTOP */}
      {isDesktop && (
        <div className={classes.content}>
          {mode === Modes.startingSoon && start_date && (
            <StartingSoon start_date={start_date} />
          )}
          {mode === Modes.countdown && end_date && (
            <Countdown end_date={end_date} />
          )}
          {mode === Modes.ended && <Ended />}
          <div className={classes.buttons}>
            <Button
              color="primary"
              size="large"
              endIcon={<OpenInNewIcon />}
              className={classNames(classes.button, classes.campaignBtn)}
              href={link}
              target="_blank"
            >
              View campaign
            </Button>
            <Button
              color="secondary"
              size="large"
              endIcon={<OpenInNewIcon />}
              className={classes.button}
              component={Link}
              to={paths.LEADERBOARD}
            >
              View leaderboard
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function Countdown({ end_date }: { end_date: Date }) {
  const classes = styles({});
  return (
    <div className={classes.countDown}>
      <div className={classes.countdownTitle}>Campaign ends in:</div>
      <CountdownComp
        date={new Date(end_date)}
        intervalDelay={1000}
        renderer={({ days, hours, minutes, seconds }) => {
          return (
            <div className={classes.timeCards}>
              <div className={classes.timeCard}>
                <div className={classes.time}>{days}</div>
                <div>days</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{hours}</div>
                <div>hours</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{minutes}</div>
                <div>minutes</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{seconds}</div>
                <div>seconds</div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}

function StartingSoon({ start_date }: { start_date: Date }) {
  const classes = styles({});
  return (
    <div className={classes.startingEndedContainer}>
      <div className={classes.hourglassWrapper}>
        <HourGlassSVG />
      </div>
      <div>
        <div className={classes.startingEndedTitle}>
          Campaign starting soon!
        </div>
        <div>{format(new Date(start_date), "LLLL d, yyyy 'at' h:mmaaa")}</div>
      </div>
    </div>
  );
}

function Ended() {
  const classes = styles({});
  return (
    <div className={classes.startingEndedContainer}>
      <div className={classes.confettiWrapper}>
        <ConfettiSVG />
      </div>

      <div>
        <div className={classes.startingEndedTitle}>Campaign complete!</div>
        <div>Thank you for making this campaign a success</div>
      </div>
    </div>
  );
}

function Links({ view }: { view: ShareCardView }) {
  const classes = styles({});
  const [showDrawer, setShowDrawer] = useState(false);
  const { volunteerLabelLC } = useSelector(getVolunteerLabel);

  return (
    <div className={classes.linkContainer}>
      <div className={classes.linkIcon}>
        {view === ShareCardView.orgShare ? (
          <ShareIconSVG />
        ) : (
          <InviteIcon color="primary" />
        )}
      </div>

      <div>
        <div className={classes.linkTitle}>
          {view === ShareCardView.orgShare
            ? "Share campaign"
            : `Invite ${volunteerLabelLC}`}
        </div>
        <Button
          variant="text"
          color="primary"
          size="small"
          startIcon={<InsertLinkIcon />}
          onClick={() => setShowDrawer(true)}
        >
          TAP FOR{" "}
          {view === ShareCardView.orgShare
            ? "SHARING OPTIONS"
            : "LINK & QR CODE"}
        </Button>
      </div>
      {showDrawer && (
        <LinksDrawer onClose={() => setShowDrawer(false)} view={view} />
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  skeleton: {
    width: "100%",
    borderRadius: 8,
    height: 90,
    marginBottom: 32,
  },
  container: {
    width: "100%",
    position: "relative",
    zIndex: 9,
    borderRadius: 8,
    padding: 24,
    marginBottom: 32,
    background: "linear-gradient(0deg, #D8ECFB 0%, #BCDFFE 100%)",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% + 32px)",
      position: "relative",
      zIndex: 9,
      marginLeft: -16,
      marginRight: -16,
      borderRadius: 0,
      background: "none",
      marginBottom: 16,
      padding: 0,
    },
  },
  gradientBG: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 260,
    background: `linear-gradient(0deg, rgba(227,242,254,0.07) 0%, #D8ECFB 100%, #D8ECFB 100%)`,
  },
  content: {
    width: "100%",
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      padding: 16,
    },
  },
  greeting: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "18px",
      fontWeight: 600,
      letterSpacing: 0,
      marginBottom: 5,
      overflowWrap: "anywhere",
    },
  },
  campaignId: {
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.text.secondary,
      overflowWrap: "anywhere",
      fontSize: 12,
      letterSpacing: 0.9,
      lineHeight: "18px",
    },
  },
  buttons: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginTop: 32,
    },
  },
  button: {
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.3,
    minWidth: 209,
    width: 209,
    borderRadius: 21,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      letterSpacing: 0.37,
      borderRadius: 4,
      minWidth: 146,
      width: 146,
    },
  },
  campaignBtn: {
    marginRight: 16,
  },
  spacer: {
    height: "100%",
    width: 40,
    marginRight: 40,
    borderRight: "1px solid #FFFFFF",
  },
  rightSection: {
    width: 293,
    minWidth: 293,
    marginRight: 80,
    [theme.breakpoints.down("sm")]: {
      width: 202,
      minWidth: 202,
      marginRight: 0,
    },
  },
  // COUNTDOWN
  countDown: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
  },
  countdownTitle: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    marginRight: 32,
  },
  timeCards: {
    display: "flex",
    alignItems: "center",
  },
  timeCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 48,
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: "13px",
    color: theme.palette.text.secondary,
    marginRight: 24,
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  time: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "26px",
    color: theme.palette.primary.main,
    paddingBottom: 6,
  },
  // STARTING / ENDED
  startingEndedContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    letterSpacing: 0.11,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      borderRadius: 8,
      backgroundColor: "#FFFFFF",
      position: "relative",
      margin: "0 16px 16px 16px",
      padding: 16,
      fontSize: 14,
      letterSpacing: 0.1,
    },
  },
  startingEndedTitle: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "24px",
    paddingBottom: 7,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.2,
      lineHeight: "17px",
    },
  },
  hourglassWrapper: {
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      height: 52,
      width: 52,
      borderRadius: 8,
      backgroundColor: "#E3F2FE",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 16,
      marginBottom: 0,
    },
  },
  confettiWrapper: {
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
    },
  },
  // LINKS
  linkContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      borderRadius: 8,
      backgroundColor: "#FFFFFF",
      position: "relative",
      margin: "0 16px 16px 16px",
      padding: 16,
      display: "flex",
      maxWidth: "unset",
    },
  },
  linkIcon: {
    [theme.breakpoints.down("sm")]: {
      height: 48,
      width: 48,
      borderRadius: 8,
      backgroundColor: "#F1F9FF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 20,
    },
  },
  linkTitle: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "18px",
    marginBottom: 8,
  },
}));
