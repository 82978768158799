import { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
import { makeStyles, Button, Fade, IconButton } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { sharedStyles } from "../shared.styles";
import { Contact, GuidedSetupScreen } from "../../../types";
import { AFFILIATES, CONTACT_ID_PARAM } from "../../../../lib";
import { affiliateLinkWithTS, textMessageLink } from "../../../lib";
import { TextContactsSVG } from "../SVGs/TextContactsSVG";
import classNames from "classnames";
import { RootState, useAppDispatch } from "../../../types/state";
import { ButtonSpinner, CircularLoader } from "../../../components";
import { Tooltip } from "../components/Tooltip";
import { contactActions, getCampaignId } from "../../../state";

export function TextContacts(props: GuidedSetupScreen) {
  const {
    activeScreen,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setDisableNext,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const [textContacts, setTextContacts] = useState<Contact[] | null>(null);
  const [noTextContacts, setNoTextContacts] = useState(false);
  const { contactsFetched, contacts } = useSelector(
    (state: RootState) => state.contacts,
  );
  const { orgName, webpageLink } = useSelector(
    (state: RootState) => state.volunteerSetup,
  );
  const link = affiliateLinkWithTS(
    webpageLink,
    AFFILIATES.VOLUNTEER_SETUP_SHARING_LINKS,
  );

  useEffect(() => {
    if (contactsFetched) {
      const TC = contacts.filter(({ phone }) => Boolean(phone));
      if (TC.length) {
        setTextContacts(TC);
        setNoTextContacts(false);
      } else {
        setTextContacts(null);
        setNoTextContacts(true);
      }
    }
  }, [contactsFetched, contacts]);

  useEffect(() => {
    if (activeScreen === "TEXT_CONTACTS") {
      setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]);

  useEffect(() => {
    if (nextTriggeredBy === "TEXT_CONTACTS") {
      handleNext();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  if (activeScreen !== "TEXT_CONTACTS") return <></>;
  return (
    <div className={sharedClasses.fullWidthScreenContainer}>
      <TextContactsSVG />
      <h1 className={classNames(sharedClasses.screenHeader, classes.header)}>
        Text your contacts for their support
      </h1>

      <CircularLoader show={!contactsFetched} />

      {contactsFetched && noTextContacts && (
        <Fragment>
          <p className={sharedClasses.screenParagraph}>
            You have not added any fundraising contacts with phone numbers
          </p>
          <div className={classes.noContactsSpacer} />
          <div className={sharedClasses.tooltipWrapper}>
            <Tooltip
              text="Click to share a link to your page via text message"
              bottom={4}
            />
          </div>
          <Button
            color="primary"
            startIcon={<SmsOutlinedIcon />}
            className={classes.noContactsButton}
            href={textMessageLink(link, orgName, true)}
          >
            Send a text
          </Button>
        </Fragment>
      )}

      {contactsFetched && textContacts && (
        <div className={classes.contactsContainer}>
          {textContacts.map((c, i) => (
            <Row key={i} contact={c} link={link} orgName={orgName} />
          ))}
        </div>
      )}
    </div>
  );
}

type RowProps = {
  contact: Contact;
  link: string;
  orgName: string;
};
enum Modes {
  text = "text",
  supported = "supported",
  conf = "conf",
  confSuccess = "confSuccess",
  texted = "texted",
}
function Row({ contact, link: _link, orgName }: RowProps) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const { id, name, phone, alreadySupported, lastManualText } = contact;
  const campaign_id = useSelector(getCampaignId);
  const link = `${_link}&${CONTACT_ID_PARAM}=${id}`;
  const [mode, setMode] = useState<Modes>(Modes.text);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (alreadySupported) setMode(Modes.supported);
  }, [alreadySupported]);

  const markSent = async () => {
    setSubmitting(true);
    const sent = await dispatch(
      contactActions.createManualTextSent({
        contact_id: id,
        campaign_id,
      }),
    );
    if (sent) {
      setMode(Modes.confSuccess);
      setTimeout(closeConf, 2500);
    }
    setSubmitting(false);
  };

  const closeConf = () => setMode(Modes.texted);

  return (
    <div className={classes.row}>
      <div className={classes.nameAndNum}>
        <div className={classes.name}>{name}</div>
        <div className={classes.number}>{phone}</div>
        {lastManualText && (
          <div className={classes.lastTxt}>
            You texted on {format(new Date(lastManualText.created_at), "MMM d")}
          </div>
        )}
      </div>

      {mode !== Modes.text && (
        <div className={classes.checkContainer}>
          <CheckCircleIcon className={classes.checkIcon} />
          <div className={classes.checkTxt}>
            {mode === Modes.texted ? "Texted" : "Already supported"}
          </div>
        </div>
      )}
      {mode === Modes.text && (
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            variant="text"
            size="small"
            startIcon={<SmsOutlinedIcon />}
            href={textMessageLink(link, orgName, true, phone)}
            className={classes.button}
            onClick={() => setTimeout(() => setMode(Modes.conf), 2000)}
          >
            TEXT
          </Button>
        </div>
      )}
      <Fade
        in={mode === Modes.conf || mode === Modes.confSuccess}
        timeout={{ appear: 100, exit: 800 }}
      >
        <div className={classes.confirm}>
          <div className={classes.confTxt}>
            {mode === Modes.confSuccess && (
              <div>Well done! Keep reaching out and making a difference.</div>
            )}
            {mode === Modes.conf && (
              <Fragment>
                <div className={classes.confName}>{name}</div>
                <div>Did you text this contact?</div>
              </Fragment>
            )}
          </div>
          {mode === Modes.conf && (
            <div className={classes.confButtons}>
              <Button
                size="small"
                className={classes.confYes}
                onClick={markSent}
                disabled={submitting}
              >
                Yes
                <ButtonSpinner show={submitting} customColor="#5FEA6B" />
              </Button>
              <Button
                size="small"
                className={classes.confNo}
                onClick={() => setMode(Modes.text)}
                disabled={submitting}
              >
                No
              </Button>
            </div>
          )}
          {mode === Modes.confSuccess && (
            <IconButton onClick={closeConf} className={classes.closeConf}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </Fade>
    </div>
  );
}

const styles = makeStyles(theme => ({
  header: {
    padding: "0 16px",
  },
  spacer: {
    height: 76,
  },
  noContactsSpacer: {
    height: 76,
  },
  noContactsButton: {
    width: 300,
  },
  contactsContainer: {
    paddingTop: 24,
    width: "100%",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: "1px solid #EAEBF3",
    overflowWrap: "anywhere",
    position: "relative",
  },
  nameAndNum: {
    paddingLeft: 24,
    paddingRight: 12,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "18px",
    marginBottom: 4,
  },
  number: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "14px",
  },
  lastTxt: {
    fontSize: 12,
    letterSpacing: 0.4,
    paddingTop: 6,
    color: theme.palette.secondary2.main,
  },
  buttonWrapper: {
    marginRight: 16,
    width: "fit-content",
    minWidth: "fit-content",
  },
  button: {
    textWrap: "nowrap",
  },
  checkContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    color: "#1AC846",
  },
  checkTxt: {
    marginLeft: 8,
    marginRight: 20,
    fontSize: 14,
    letterSpacing: 0.15,
    textWrap: "nowrap",
  },
  confirm: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#FFF4F4",
    zIndex: 100,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px",
  },
  confTxt: {
    overflow: "hidden",
    marginRight: 16,
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "22px",
  },
  confName: {
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    marginRight: 0,
  },
  confButtons: {
    minWidth: 104,
    display: "flex",
    justifyContent: "flex-end",
  },
  confYes: {
    minWidth: 48,
    maxWidth: 48,
    backgroundColor: "#5FEA6B",
    marginRight: 8,
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    "&:hover": {
      opacity: 0.7,
      backgroundColor: "#5FEA6B",
    },
  },
  confNo: {
    minWidth: 48,
    maxWidth: 48,
    backgroundColor: theme.palette.error.main,
    color: "#FFFFFF",
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    "&:hover": {
      opacity: 0.7,
      backgroundColor: theme.palette.error.main,
    },
  },
  closeConf: {
    marginRight: -8,
  },
}));
