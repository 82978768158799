import { useState, useEffect, Fragment } from "react";
import { Button, makeStyles, useMediaQuery } from "@material-ui/core";
import CountdownComp from "react-countdown-now";
import { format, isFuture, isPast } from "date-fns";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import BGImage from "../../../assets/volunteerDashboardMobileHeaderImage.svg";
import { HourGlassSVG } from "../../../assets/HourGlassSVG";
import { ConfettiSVG } from "../../../assets/ConfettiSVG";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { VolunteerDashboardHeaderSVG } from "../../../assets/VolunteerDashboardHeaderSVG";
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from "../../../types";
import { affiliateLinkWithTS } from "../../../lib";
import { AFFILIATE_SUB_CODES, AFFILIATES } from "../../../../lib";
const SHOW_DESKTOP_IMAGE_MIN_WIDTH = 1100;
enum Modes {
  none = "none",
  countdown = "countdown",
  startingSoon = "startingSoon",
  ended = "ended",
}

export function VolunteerHeader() {
  const classes = styles();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const [mode, setMode] = useState<Modes>(Modes.none);
  const { volunteerFirstName, start_date, end_date, webpageLink } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const link = affiliateLinkWithTS(
    webpageLink,
    AFFILIATES.DASHBOARD_SHARING_LINKS,
    AFFILIATE_SUB_CODES.LINK,
  );
  const sellerId = useSelector(({ user }: RootState) => user.volunteerId);
  const sidebarOpen = useSelector(({ ui }: RootState) => ui.sidebarOpen);
  const showDesktopImage = useMediaQuery(
    `(min-width: ${SHOW_DESKTOP_IMAGE_MIN_WIDTH +
      (sidebarOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH)}px)`,
  );

  useEffect(() => {
    if (start_date && isFuture(new Date(start_date))) {
      setMode(Modes.startingSoon);
    } else if (end_date && isPast(new Date(end_date))) {
      setMode(Modes.ended);
    } else if (end_date && isFuture(new Date(end_date))) {
      setMode(Modes.countdown);
    }
  }, [start_date, end_date]);

  return (
    <div className={classes.container}>
      {/* MOBILE */}
      {isMobile && (
        <Fragment>
          <div className={classes.gradientBG} />
          <div className={classes.imageBG} />
          <div className={classes.content}>
            <div className={classes.leftSection}>
              <div className={classes.greeting}>Hi, {volunteerFirstName}!</div>
              <div className={classes.sellerId}>Seller ID {sellerId}</div>
              <Button
                size="small"
                variant="text"
                color="primary"
                endIcon={<OpenInNewIcon />}
                className={classes.button}
                href={link}
                target="_blank"
              >
                GO TO MY PAGE
              </Button>
            </div>
            <div className={classes.rightSection} />
          </div>
          {mode === Modes.startingSoon && start_date && (
            <StartingSoon start_date={start_date} />
          )}
          {mode === Modes.ended && <Ended />}
        </Fragment>
      )}

      {/* DESKTOP */}
      {isDesktop && (
        <Fragment>
          <div className={classes.content}>
            <div className={classes.leftSection}>
              <div>
                <div className={classes.greeting}>
                  Hi, {volunteerFirstName}!
                </div>
                <div className={classes.sellerId}>Seller ID {sellerId}</div>
                <Button
                  color="primary"
                  endIcon={<OpenInNewIcon />}
                  className={classes.button}
                  href={link}
                  target="_blank"
                >
                  Go to my page
                </Button>
              </div>
              <div className={classes.spacer} />
              <div>
                {mode === Modes.startingSoon && start_date && (
                  <StartingSoon start_date={start_date} />
                )}
                {mode === Modes.countdown && end_date && (
                  <Countdown end_date={end_date} />
                )}
                {mode === Modes.ended && <Ended />}
              </div>
            </div>
            {showDesktopImage && (
              <div className={classes.rightSection}>
                <VolunteerDashboardHeaderSVG />
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

function Countdown({ end_date }: { end_date: Date }) {
  const classes = styles({});
  return (
    <Fragment>
      <div className={classes.countdownTitle}>Time remaining</div>
      <CountdownComp
        date={new Date(end_date)}
        intervalDelay={1000}
        renderer={({ days, hours, minutes, seconds }) => {
          return (
            <div className={classes.timeCards}>
              <div className={classes.timeCard}>
                <div className={classes.time}>{days}</div>
                <div>days</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{hours}</div>
                <div>hours</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{minutes}</div>
                <div>minutes</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{seconds}</div>
                <div>seconds</div>
              </div>
            </div>
          );
        }}
      />
    </Fragment>
  );
}

function StartingSoon({ start_date }: { start_date: Date }) {
  const classes = styles({});
  return (
    <div className={classes.startingContainer}>
      <div className={classes.hourglassWrapper}>
        <HourGlassSVG />
      </div>
      <div>
        <div className={classes.startingTitle}>Campaign starting soon</div>
        <div>{format(new Date(start_date), "LLLL d, yyyy 'at' h:mmaaa")}</div>
      </div>
    </div>
  );
}

function Ended() {
  const classes = styles({});
  return (
    <div className={classes.endedContainer}>
      <div className={classes.confettiWrapper}>
        <ConfettiSVG />
      </div>

      <div>
        <div className={classes.endedTitle}>Campaign complete!</div>
        <div>Thank you for making our campaign a success</div>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    position: "relative",
    zIndex: 9,
    borderRadius: 8,
    marginBottom: 32,
    background:
      "linear-gradient(306.09deg, #F5F9FC 0%, #D8ECFB 100%, #D8ECFB 100%)",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% + 32px)",
      position: "relative",
      zIndex: 9,
      marginLeft: -16,
      marginRight: -16,
      borderRadius: 0,
      background: "none",
      marginBottom: 0,
    },
  },
  gradientBG: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: 187,
    background: `linear-gradient(0deg, rgba(227,242,254,0.07) 0%, #D8ECFB 100%, #D8ECFB 100%)`,
  },
  imageBG: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    background: `url('${BGImage}') no-repeat right top`,
  },
  content: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
  },
  leftSection: {
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "unset",
      padding: "24px 6px 14px 16px",
      position: "relative",
    },
  },
  greeting: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
    overflowWrap: "anywhere",
    marginBottom: 11,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: "20px",
      marginBottom: 10,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 20,
      lineHeight: "24px",
    },
  },
  sellerId: {
    fontSize: 16,
    letterSpacing: 0.11,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.09,
      lineHeight: "13px",
      marginBottom: 8,
    },
  },
  button: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    minWidth: 200,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      fontSize: 13,
      letterSpacing: 0.37,
      minWidth: 136,
      width: 136,
    },
  },
  spacer: {
    height: "100%",
    width: 40,
    marginRight: 40,
    borderRight: "1px solid #FFFFFF",
  },
  rightSection: {
    width: 293,
    minWidth: 293,
    marginRight: 80,
    [theme.breakpoints.down("sm")]: {
      width: 202,
      minWidth: 202,
      marginRight: 0,
    },
  },
  // COUNTDOWN
  countdownTitle: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    lineHeight: "16px",
    marginBottom: 16,
  },
  timeCards: {
    display: "flex",
    alignItems: "center",
  },
  timeCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 89,
    minWidth: 66,
    borderRadius: 8,
    backgroundColor: "#F1F9FF",
    padding: "0 4px",
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: "13px",
    color: theme.palette.text.secondary2,
    marginRight: 16,
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  time: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "26px",
    color: theme.palette.primary.main,
    paddingBottom: 4,
  },
  // STARTING SOON
  startingContainer: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      borderRadius: 8,
      backgroundColor: "#FFFFFF",
      position: "relative",
      margin: "0 16px 16px 16px",
      padding: 16,
      display: "flex",
      alignItems: "center",
    },
  },
  hourglassWrapper: {
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      height: 52,
      width: 52,
      borderRadius: 8,
      backgroundColor: "#E3F2FE",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 16,
      marginBottom: 0,
    },
  },
  startingTitle: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "17px",
    paddingBottom: 8,
    color: theme.palette.text.primary,
  },
  // ENDED
  endedContainer: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "20px",
    maxWidth: 230,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      borderRadius: 8,
      backgroundColor: "#FFFFFF",
      position: "relative",
      margin: "0 16px 16px 16px",
      padding: 16,
      display: "flex",
      maxWidth: "unset",
    },
  },
  endedTitle: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "17px",
    paddingBottom: 8,
    color: theme.palette.text.primary,
  },
  confettiWrapper: {
    marginBottom: 6,
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
      marginBottom: 0,
    },
  },
}));
