import React, { useState, useEffect } from "react";
import { ChangePW } from "./ChangePW";
import { UpdateProfile } from "./UpdateProfile";
import { Notification, useNotify } from "react-admin";
import { Paper, Tabs, Tab, Divider } from "@material-ui/core";
import { APIClient, generateAuthHeader } from "../../lib";
import { connect } from "react-redux";
import {
  storeProfile as storeProfileAction,
  clearProfile as clearProfileAction,
} from "../../customState";
import { styles } from "./Profile.styles";

const Profile = props => {
  const classes = styles();
  const notify = useNotify();
  const { storeProfile, clearProfile } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/users/profile", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) {
        return notify(errorMessage, "warning");
      }
      storeProfile(data);
    };
    fetch();
  }, [notify, storeProfile]);

  useEffect(() => {
    return clearProfile;
  }, [clearProfile]);

  const handleChange = (_event, newValue) => {
    setActiveIndex(newValue);
  };

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Tabs value={activeIndex} onChange={handleChange} variant="scrollable">
          <Tab label="Update Profile" />
          <Tab label="Change Password" />
        </Tabs>
        <Divider />
        <div className={classes.contentContainer}>
          {activeIndex === 0 && <UpdateProfile />}
          {activeIndex === 1 && <ChangePW />}
        </div>

        <Notification />
      </Paper>
    </div>
  );
};

export default connect(undefined, {
  storeProfile: storeProfileAction,
  clearProfile: clearProfileAction,
})(Profile);
