const profileTypes = {
  PROFILE: "PROFILE",
  PROFILE_UPDATE: "PROFILE_UPDATE",
  CLEAR_PROFILE: "CLEAR_PROFILE",
};

const storeProfile = data => ({
  type: profileTypes.PROFILE,
  payload: { ...data },
});

const updateProfile = data => ({
  type: profileTypes.PROFILE_UPDATE,
  payload: { ...data },
});

const clearProfile = () => ({
  type: profileTypes.CLEAR_PROFILE,
});

export { profileTypes, storeProfile, updateProfile, clearProfile };
