import {
  AFFILIATE_SC_PARAM,
  AFFILIATE_SUB_CODES,
  AFFILIATE_TS_PARAM,
} from "../../lib";

export function sanitizeIntegersOnlyInput(value: string): string {
  if (!value || value === "0") return "";
  return value.replace(/\D/g, "");
}

export function sanitizeQuillHtml(html: string | undefined): string | null {
  if (!html || html === "<p><br></p>") return null;
  return html;
}

export function formatMoney(_num: number | null | undefined | unknown): string {
  const num = _num ? Number(_num) : 0;
  const padded = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${padded}`;
}

export function formatMoneyUnPadded(
  _num: number | null | undefined | unknown,
): string {
  const num = _num ? Number(_num) : 0;
  const paddedNum = !Number.isInteger(num) ? num.toFixed(2) : num;
  const formattedNum = paddedNum
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${formattedNum}`;
}

export function formatNumber(num: unknown) {
  if (!num) return 0;
  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function addNumbers(num1: any = 0, num2: any = 0, num3: any = 0) {
  return Number((Number(num1) + Number(num2) + Number(num3)).toFixed(2));
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function formatTimeString(time: string) {
  if (!time) return "";
  const hoursInt = parseInt(time.substring(0, 3));
  const minutes = time.substring(3, 5);
  const amPm = hoursInt > 11 ? "PM" : "AM";
  let hours = hoursInt;
  if (hoursInt === 0) hours = 12;
  else if (hoursInt > 12) hours = hoursInt - 12;
  return `${hours}:${minutes} ${amPm}`;
}

export const stateOptions = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "District of Columbia", code: "DC" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
];

export function validateAndGetInt(value: any) {
  if (/^\d+$/.test(value)) return Number(value);
  else return null;
}

export function timestamp() {
  return new Date().getTime();
}

export function affiliateLinkWithTS(
  link: string,
  affiliate: string,
  affiliateSubCode?: string,
) {
  let l = `${link}?a=${affiliate}&${AFFILIATE_TS_PARAM}=${timestamp()}`;
  if (affiliateSubCode) l = `${l}&${AFFILIATE_SC_PARAM}=${affiliateSubCode}`;
  return l;
}

export function copyLinkWithSubAffiliate(link: string) {
  return `${link}&${AFFILIATE_SC_PARAM}=${AFFILIATE_SUB_CODES.LINK}`;
}

export function nonEmptyArray<T>(array: any): array is T[] {
  return Array.isArray(array) && array.length > 0;
}
