import React, { useState } from "react";
import {
  Notification,
  downloadCSV,
  useNotify,
  FileInput,
  FileField,
  useRefresh,
} from "react-admin";
import {
  Dialog,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/GetApp";
import jsonExport from "jsonexport/dist";
import { APIClient, generateAuthHeader } from "../../lib";
import { styles } from "./Volunteer.styles";

export function UploadVolunteersModal(props) {
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const { setUploadModal, campaignId } = props;
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [failedRowsArr, setFailedRows] = useState(null);

  const onSubmit = async values => {
    const { csv } = values;
    if (!csv) return notify("Please upload A CSV file", "error");
    setDisableSubmit(true);
    const reader = new FileReader();
    reader.readAsText(csv.rawFile);
    reader.onload = async () => {
      const res = await APIClient.post(
        "/volunteers/csv-upload",
        { csv: reader.result, campaignId },
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        setDisableSubmit(false);
        return notify(errorMessage, "warning");
      }

      if (!data.failedRows.length) {
        refresh();
        setUploadModal(false);
        return notify("your Students have been successfully uploaded", {
          type: "info",
        });
      }
      // TODO: if there are failed rows refetch the list after they view the failed ones & close the modal
      setFailedRows(data.failedRows);
    };
    reader.onerror = console.error;
  };

  return (
    <Dialog open={true} onClose={() => setUploadModal(false)} maxWidth={false}>
      <div className={classes.importContainer}>
        <div className={classes.importClose}>
          <IconButton onClick={() => setUploadModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        {!failedRowsArr && (
          <div>
            <div className={classes.importSubHeader}>Step 1.</div>
            <div className={classes.downloadSection}>
              <div className={classes.importTxt}>Download the CSV template</div>
              <div>
                <Button
                  className={classes.importButtons}
                  onClick={exportTemplate}
                >
                  <DownloadIcon /> &nbsp;&nbsp;&nbsp;download
                </Button>
              </div>
            </div>

            <div className={classes.importSubHeader}>Step 2.</div>
            <div className={classes.importTxt}>
              Add your Student list and save/export it as a CSV.
            </div>
            <div className={classes.importTxt}>
              Make sure to use the headers of the columns exactly how they
              appear in the download file.
            </div>
            <ul>
              <li>
                Fill in the row_number so if any of them aren't successful, we
                can send you back which ones failed.
              </li>
              <li>first_name & last_name or full_name are required fields.</li>
              <li>row_number and email are required fields.</li>
              <li>
                If you want the Student to be activated, set `activate` to 'yes'
                (case sensitive).
              </li>
              <li>
                {`If the Email is being shared on multiple Students, set
                \`can_duplicate_email\` to 'yes' (case sensitive).\n(PLEASE NOTE: This should only be allowed where multiple student accounts must share a common email address (e.g. parent/guardian). Otherwise, students should not be allowed to create duplicate accounts.)
                `}
              </li>
              <li>
                {`To associate shared Email addresses, set
                \`associate_shared_emails\` to 'yes' (case sensitive).\n(Associated users share the same email address and all associated user accounts can be accessed from any associated user login.)
                `}
              </li>
            </ul>

            <div className={classes.importSubHeader}>Step 3.</div>
            <div className={classes.importTxt}>Upload your CSV file</div>

            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <div className={classes.fileDrop}>
                      <FileInput
                        source="csv"
                        label="File Upload"
                        accept={
                          "application/vnd.ms-excel, text/csv, text/plain"
                        }
                        multiple={false}
                      >
                        <FileField source="src" title="title" />
                      </FileInput>
                    </div>

                    <div className={classes.importWarning}>
                      This Bulk Student Import will trigger an invite/welcome
                      email to all uploaded students
                      <div>
                        even if campaign has not yet started and/or has not yet
                        been activated
                      </div>
                    </div>

                    <Button
                      type="submit"
                      disabled={disableSubmit}
                      className={classes.importButtons}
                    >
                      {disableSubmit && (
                        <CircularProgress
                          size={20}
                          className={classes.progress}
                        />
                      )}
                      {!disableSubmit && (
                        <div className={classes.saveBtnContent}>
                          <SaveIcon />
                          &nbsp;&nbsp;&nbsp;save
                        </div>
                      )}
                    </Button>
                  </form>
                );
              }}
            />
          </div>
        )}
        {failedRowsArr && (
          <div>
            <div className={classes.importSubHeader}>
              Some of your Students failed to upload successfully
            </div>
            <table className={classes.failedUploadTable}>
              <thead>
                <tr>
                  <th className={classes.failedUploadTh}>Row Number</th>
                  <th className={classes.failedUploadTh}>Message</th>
                </tr>
              </thead>
              <tbody>
                {failedRowsArr.map(o => {
                  const { row_number, msg } = o;
                  return (
                    <tr key={row_number}>
                      <td className={classes.failedUploadCell}>{row_number}</td>
                      <td className={classes.failedUploadCell}>{msg}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Button
              className={classes.importButtons}
              onClick={() => setUploadModal(false)}
            >
              OK
            </Button>
          </div>
        )}
      </div>
      <Notification />
    </Dialog>
  );
}

function exportTemplate() {
  jsonExport(
    [
      {
        row_number: undefined,
        first_name: undefined,
        last_name: undefined,
        full_name: undefined,
        email: undefined,
        phone: undefined,
        class_name: undefined,
        activate: undefined,
        team_id: undefined,
        can_duplicate_email: undefined,
        associate_shared_emails: undefined,
        external_id: undefined,
      },
    ],
    (err, csv) => {
      downloadCSV(csv, "Student Upload Template");
    },
  );
}
