import { APIAuthClient } from "../../lib";
import { APIRes } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

const companyTypes = {
  COMPANY_CONFIGS_SET: "COMPANY_CONFIGS_SET",
};

const companyActions = {
  fetchCompanyConfigs(): AppThunkAction {
    return async (dispatch, getState) => {
      const {
        user: { companyId },
      } = getState();
      if (!companyId) return;
      const url = `/companies/${companyId}/configs`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));

      dispatch({
        type: companyTypes.COMPANY_CONFIGS_SET,
        data,
      });
    };
  },
};

export { companyActions, companyTypes };
