import { GenericAutocomplete } from "../../types";
import {
  CommunicationAutocomplete,
  CommunicationTypes,
} from "../../types/communications";
import { RootState } from "../../types/state";
import { communicationTypes } from "./actions";

type CommunicationState = {
  communicationCategoryAutocomplete: GenericAutocomplete;
  communicationAutocomplete: CommunicationAutocomplete;
  communicationAutocompleteIdMap: { [k: string]: { id: number; name: string } };
};

const initialState: CommunicationState = {
  communicationCategoryAutocomplete: [],
  communicationAutocomplete: [],
  communicationAutocompleteIdMap: {},
};

export const communicationReducers = (
  state = initialState,
  action: any,
): CommunicationState => {
  switch (action.type) {
    case communicationTypes.COMMUNICATION_AUTOCOMPLETE_SET:
      return {
        ...state,
        ...action.data,
      };
    case communicationTypes.COMMUNICATION_CATEGORIES_AUTOCOMPLETE_SET:
      return {
        ...state,
        communicationCategoryAutocomplete: action.data,
      };
    default:
      return state;
  }
};

export function getAvailableCommunicationTypes(state: RootState) {
  const { contactTextingEnabled, volunteerTextingEnabled } = state.company;
  const contactTexts = {
    [CommunicationTypes.contactsFirstText]: true,
    [CommunicationTypes.contactsSecondText]: true,
    [CommunicationTypes.contactsThirdText]: true,
  };
  const volunteerTexts = {
    [CommunicationTypes.activeStudentsFirstText]: true,
    [CommunicationTypes.activeStudentsSecondText]: true,
    [CommunicationTypes.activeStudentsThirdText]: true,
    [CommunicationTypes.activeStudentsFourthText]: true,
  };

  return Object.values(CommunicationTypes).filter(value => {
    if (contactTextingEnabled && volunteerTextingEnabled) return true;
    if (contactTexts[value] && !contactTextingEnabled) return false;
    if (volunteerTexts[value] && !volunteerTextingEnabled) return false;
    return true;
  });
}
