import { ListProps } from "../../../types";
import {
  FiltersDrawer,
  FilterWrapper,
  SwitchFilter,
} from "../../../components";
import { Fragment } from "react";
import { CommunicationFields } from "../../../types/communications";
import { makeStyles, MenuItem, TextField } from "@material-ui/core";
import { RootState } from "../../../types/state";
import { useSelector } from "react-redux";
import { getAvailableCommunicationTypes } from "../../../state";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function CommunicationFilters({ onClose, listProps }: Props) {
  const { getFilterValue, isActiveFilter, updateTempFilters } = listProps;
  const classes = styles();
  const categories = useSelector(
    ({ communication }: RootState) =>
      communication.communicationCategoryAutocomplete,
  );
  const communicationOptions = useSelector(getAvailableCommunicationTypes);

  const onCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target,
      currentTarget: { attributes },
    } = e;
    const category = attributes.getNamedItem("data-category")?.value || "";
    updateTempFilters({
      field: CommunicationFields.category_id,
      value: target.value,
      chipText: `Category - ${category}`,
    });
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target,
      currentTarget: { attributes },
    } = e;
    const type = attributes.getNamedItem("data-type")?.value || "";
    updateTempFilters({
      field: CommunicationFields.type,
      value: target.value,
      chipText: `Type - ${type}`,
    });
  };

  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header="Filter communications"
    >
      <Fragment>
        <SwitchFilter
          field={CommunicationFields.include_inactive}
          chipText="Include inactive"
          header="Include inactive communication"
          switchLabel="Include inactive"
          listProps={listProps}
        />
        <FilterWrapper
          header="Category"
          active={isActiveFilter(CommunicationFields.category_id)}
        >
          <TextField
            select
            fullWidth
            value={getFilterValue(CommunicationFields.category_id)}
            onChange={onCategoryChange}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem className={classes.labelOption} value="">
              Select a category
            </MenuItem>
            {categories.map(({ id, name }, index) => (
              <MenuItem key={index} value={id} data-category={name}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </FilterWrapper>
        <FilterWrapper
          header="Type"
          active={isActiveFilter(CommunicationFields.type)}
        >
          <TextField
            select
            fullWidth
            value={getFilterValue(CommunicationFields.type)}
            onChange={onTypeChange}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem className={classes.labelOption} value="">
              Select a type
            </MenuItem>
            {communicationOptions.map((value, index) => (
              <MenuItem key={index} value={value} data-type={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </FilterWrapper>
      </Fragment>
    </FiltersDrawer>
  );
}

const styles = makeStyles(theme => ({
  labelOption: {
    color: theme.palette.text.secondary2,
  },
}));
