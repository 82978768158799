import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { APIRes, TeamsAutocomplete, ToastTypes } from "../../../types";
import { APIAuthClient, requiredField } from "../../../lib";
import { useSelector } from "react-redux";
import {
  getHasTeams,
  getVolunteerLabel,
  setToast,
  teamActions,
} from "../../../state";
import { Fragment, useEffect, useState } from "react";
import {
  makeStyles,
  Switch,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import { RootState, useAppDispatch } from "../../../types/state";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { DuplicateEmailOptions } from "./DuplicateEmailOptions";
import { ResponsiveModal } from "../../../components";

type Props = {
  refreshList: () => void;
  onClose: () => void;
};
export function AddVolunteer({ refreshList, onClose: _onClose }: Props) {
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const classes = styles();
  const hasTeams = useSelector(getHasTeams);
  const { volunteerLabelSing, volunteerLabelLCSingular } = useSelector(
    getVolunteerLabel,
  );
  const {
    class_options: classOptions,
    campaignId,
    allow_family_accounts,
  } = useSelector(({ campaign }: RootState) => campaign);
  const [teams, setTeams] = useState<TeamsAutocomplete>([]);

  useEffect(() => {
    const fetch = async () => {
      const teamRes = await dispatch(
        teamActions.fetchCampaignTeamAutocomplete(campaignId),
      );
      if (teamRes) setTeams(teamRes);
    };
    if (hasTeams) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTeams, campaignId]);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const url = "/volunteers";
    const post = { campaign_id: campaignId, ...values };
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast(`${volunteerLabelSing} added`, ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ webpage_enabled: true }}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1 className={classes.title}>
                  Add a {volunteerLabelLCSingular}
                </h1>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.content}>
                <div className={classes.subheader}>
                  {volunteerLabelSing} details
                </div>
                <div className={classes.inputGroup}>
                  <Field
                    component={TextField}
                    name="first_name"
                    label="First name"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                  <Field
                    component={TextField}
                    name="last_name"
                    label="Last name"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                  <Field
                    component={TextField}
                    name="phone"
                    label="Phone"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                </div>

                <DuplicateEmailOptions />

                <div className={classes.inputGroup}>
                  {Array.isArray(classOptions) && classOptions.length > 0 && (
                    <Field
                      select
                      component={TextField}
                      name="class_name"
                      label="Class"
                      validate={requiredField}
                      className={classes.halfInput}
                    >
                      {classOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  )}
                  {teams.length > 0 && (
                    <Field
                      select
                      component={TextField}
                      name="team_id"
                      label="Team"
                      className={classes.halfInput}
                    >
                      {teams.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Field>
                  )}
                </div>

                <div className={classes.inputGroup}>
                  <Field
                    component={TextField}
                    name="external_id"
                    label="External ID"
                    className={classes.halfInput}
                  />
                </div>

                <div className={classes.line} />

                {/* WEBPAGE STATUS */}
                <div className={classes.subheader}>Webpage status</div>
                <div className={classes.statusSubTxt}>
                  By disabling the webpage it will become inaccessible online.
                </div>
                <div className={classes.statusSwitch}>
                  Disabled
                  <Field
                    name="webpage_enabled"
                    type="checkbox"
                    render={({ input, meta: _rm, ...rest }) => (
                      <Switch {...rest} {...input} className={classes.status} />
                    )}
                  />
                  Enabled
                </div>

                {/* FAMILY MEMBERS */}
                {allow_family_accounts && (
                  <Fragment>
                    <div className={classes.line} />
                    <div className={classes.subheader}>
                      Additional family members
                    </div>
                    <FieldArray name="family_members">
                      {({ fields }) => (
                        <div>
                          {fields.map((row, index) => {
                            return (
                              <div key={index} className={classes.fmRow}>
                                <Field
                                  name={`${row}.first_name`}
                                  component={TextField}
                                  placeholder="First name"
                                  validate={requiredField}
                                  className={classes.fmInput}
                                />
                                <Field
                                  name={`${row}.last_name`}
                                  component={TextField}
                                  placeholder="Last name"
                                  validate={requiredField}
                                  className={classes.fmInput}
                                />

                                {Array.isArray(classOptions) &&
                                  classOptions.length > 0 && (
                                    <Field
                                      name={`${row}.class_name`}
                                      validate={requiredField}
                                      select
                                      SelectProps={{
                                        displayEmpty: true,
                                        renderValue: (value: string) =>
                                          value !== ""
                                            ? value
                                            : "Select a Class",
                                      }}
                                      component={TextField}
                                      className={classes.fmInput}
                                    >
                                      {classOptions.map((option, i) => (
                                        <MenuItem key={i} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  )}

                                <div className={classes.removeFmWrapper}>
                                  <RemoveIcon
                                    onClick={() => fields.remove(index)}
                                    color="primary"
                                    className={classes.removeFM}
                                  />
                                </div>
                              </div>
                            );
                          })}

                          <Button
                            color="primary"
                            variant="text"
                            startIcon={<AddIcon />}
                            onClick={() =>
                              fields.push({
                                first_name: "",
                                last_name: "",
                                class_name: "",
                              })
                            }
                          >
                            FAMILY MEMBER
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </Fragment>
                )}

                <div className={classes.saveMsg}>
                  <InfoIcon className={classes.infoIcon} />
                  <div>
                    By clicking save, the {volunteerLabelLCSingular} will
                    receive an email with a link to set their password and
                    complete their account setup.
                  </div>
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      color="primary"
                      className={classes.cancel}
                      onClick={onClose}
                      disabled={submitting}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting}
                    onClick={() => form.submit()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 700,
    maxWidth: "100%",
    overflowY: "auto",
  },
  top: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px",
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 12px 16px 12px",
      position: "sticky",
      top: 0,
      backgroundColor: "#FFFFFF",
      zIndex: 9,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  content: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 16px 16px",
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  line: {
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #EAEBF3",
      marginLeft: -16,
      marginRight: -16,
      marginBottom: 24,
    },
  },
  subheader: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.34,
    lineHeight: "18px",
    marginBottom: 16,
  },
  statusSubTxt: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginBottom: 10,
    marginTop: -6,
  },
  statusSwitch: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    letterSpacing: 0.15,
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
  status: {
    margin: "0 8px",
  },
  fmRow: {
    display: "flex",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #DBDEEE",
      borderRadius: 8,
      padding: "14px 16px",
      flexDirection: "column",
      marginBottom: 16,
    },
  },
  fmInput: {
    width: "100%",
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: 16,
    },
  },
  removeFmWrapper: {
    height: 40,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      height: "unset",
    },
  },
  removeFM: {
    color: theme.palette.error.main,
    cursor: "pointer",
  },
  saveMsg: {
    width: "100%",
    display: "flex",
    color: "#003554",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    marginTop: 32,
  },
  infoIcon: {
    marginRight: 14,
  },
  bottom: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      textTransform: "unset",
      marginLeft: 0,
    },
  },
  cancel: {
    textTransform: "uppercase",
  },
}));
