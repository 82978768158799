import { Button, IconButton, makeStyles } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetAppOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { VariableHeightDrawer } from "../ui/VariableHeightDrawer";
import { CopyLink } from "..";
import { downloadExternalImage } from "../../lib";
import { useDrawerTransition } from "../../hooks/ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/state";
import {
  AFFILIATE_SC_PARAM,
  AFFILIATE_SUB_CODES,
  IS_RN_WEBVIEW,
} from "../../../lib";
import { setToast } from "../../state";
const ERR_MSG =
  "We are unable to open Instagram, check if the app is installed";

type Props = {
  link: string;
  onClose: () => void;
};
export function InstagramDrawer({ link: _link, onClose: _onClose }: Props) {
  const link = `${_link}&${AFFILIATE_SC_PARAM}=${AFFILIATE_SUB_CODES.INSTAGRAM}`;
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const classes = styles();
  const dispatch = useDispatch();
  const { instagramImage } = useSelector((state: RootState) => state.campaign);

  const savePhoto = () => {
    downloadExternalImage(instagramImage, "campaign-image");
  };

  const onOpenInstagram = () => {
    if (IS_RN_WEBVIEW) {
      if (!(window as any).ReactNativeWebView) {
        dispatch(setToast(ERR_MSG));
      } else {
        (window as any).ReactNativeWebView.postMessage(
          JSON.stringify({ openInstagram: true }),
        );
      }
    } else {
      window.location.href = "instagram://story-camera";
    }
  };

  return (
    <VariableHeightDrawer isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.closeWrapper}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <h2 className={classes.title}>Share to your Instagram story</h2>

        <div className={classes.imgWrapper}>
          <img
            className={classes.img}
            alt="photo"
            src={instagramImage as string}
          />
        </div>

        <div className={classes.step}>1. Save the photo</div>

        {IS_RN_WEBVIEW && (
          <Button
            variant="text"
            color="primary"
            onClick={savePhoto}
            startIcon={<DownloadIcon />}
            className={classes.download}
          >
            SAVE TO PHOTOS
          </Button>
        )}
        {!IS_RN_WEBVIEW && (
          <div className={classes.instructions}>
            Tap and hold the photo above to save it to your Photos
          </div>
        )}

        <div className={classes.step}>
          2. Copy the link to include in your story
        </div>

        <div className={classes.linkContainer}>
          <div>{link}</div>
          <CopyLink link={link}>
            <Button size="small" color="primary" className={classes.copy}>
              Copy
            </Button>
          </CopyLink>
        </div>

        <div className={classes.btnWrapper}>
          <Button
            color="primary"
            onClick={onOpenInstagram}
            className={classes.button}
          >
            Continue to Instagram
          </Button>
        </div>
      </div>
    </VariableHeightDrawer>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "12px 16px 0 16px",
  },
  closeWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: -4,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    marginBottom: 24,
  },
  imgWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 24,
  },
  img: {
    width: "100%",
    maxWidth: 460,
  },
  step: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "20px",
    paddingBottom: 8,
  },
  instructions: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "20px",
    marginBottom: 24,
  },
  download: {
    marginBottom: 24,
  },
  linkContainer: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    padding: "18px 12px 18px 12px",
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.11,
    lineHeight: "17px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflowWrap: "anywhere",
    marginTop: 16,
    marginBottom: 24,
  },
  copy: {
    width: 66,
    minWidth: 66,
    marginLeft: 16,
    borderRadius: 15,
  },
  btnWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: 300,
    maxWidth: "100%",
    marginBottom: 24,
  },
}));
